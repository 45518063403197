import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useState } from 'react';
import { Error } from '../../styles/error';
import { FieldLabel } from '../../styles/field-label';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  colorLabel?: string;
  mandatory?: boolean;
}
const Input: React.FC<InputProps> = ({
  name,
  label,
  colorLabel,
  mandatory,
  ...rest
}) => {
  const inputRef = React.useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const [values, setValues] = useState('');

  const inputChange = (e: any) => {
    const { value } = e.target;
    setValues(value);
  };
  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  function handleInputChangeText() {
    if (error) {
      clearError();
    }
  }

  return (
    <>
      {label ? (
        <FieldLabel htmlFor={name} className="field-label" color={colorLabel}>
          {label && (
            <>
              {label}
              <sup>
                {mandatory && <span style={{ color: '#c00' }}> *</span>}
              </sup>
            </>
          )}
        </FieldLabel>
      ) : (
        <></>
      )}

      <input
        className="input"
        id={name}
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        onKeyDown={handleInputChangeText}
        onChange={inputChange}
        {...rest}
        style={{ borderColor: `${error ? 'red' : ''}` }}
      />
      {error ? (
        <Error className="invalid-feedback d-block m-0 bold-600">{error}</Error>
      ) : (
        <></>
      )}
    </>
  );
};

Input.defaultProps = {
  colorLabel: '#404040',
};
export default Input;
