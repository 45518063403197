/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Container, Col, Table } from 'react-bootstrap';

import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import ModalCe from '../../components/custom-modal';

import { ContainerPage } from '../../styles/container-page';
import { scrollToTop } from '../../util/bibli';
import api from '../../services/api';
import { AuthContext } from '../../contexts/auth';
import Loading from '../../components/custom-loading';
import { toast } from 'react-toastify';
import Navigation from '../../components/custom-navigation';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import { trackParams } from '../../network/manager-analytics';

const Account: React.FC = () => {
  const { authenticated } = useContext(AuthContext);
  const [modal, setModal] = useState({ active: false, route: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [myPlan, setMyPlan] = useState<any>();
  const [header, setHeader] = useState<any>();
  const [vehicles_enabled, setVehicles_enabled] = useState<any>();
  const [dataProducty, setDataProducty] = React.useState<any>([]);
  const navigate = useNavigate();

  async function getAccountInfo() {
    try {
      setLoading(true);
      const response = await api.get(`/user/account-info`, {
        headers: {
          Authorization: `Bearer ${authenticated?.authUserToken}`,
        },
      });
      if (response.data) {
        setHeader(response.data.header);
        setMyPlan(response.data.my_plan);
        setVehicles_enabled(response.data.vehicles_enabled);
        setDataProducty([{ myPlan: response.data.my_plan }]);
      }
      setLoading(false);
    } catch (err: any) {
      console.error('ops! ocorreu um erro' + err);
      toast.error(`${err.response.data.error.message}. Tente Novamente!`, {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
      });
    }
  }
  async function putAccountInfo() {
    try {
      setLoading(true);
      trackParams('on_click_unsubscribe', location.pathname, [
        {
          propertie: 'Cancelar',
          value: 'Sim',
        },
      ]);
      await api.put(
        `/orders/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authenticated?.authUserToken}`,
          },
        }
      );
      setModal({
        active: false,
        route: '',
      });
      navigate('/account');
      toast.success('Plano cancelado com sucesso!', {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
      });
    } catch (err: any) {
      setModal({
        active: false,
        route: '',
      });
      navigate('/account');
      console.error('ops! ocorreu um erro' + err);
      toast.error(`${err.response.data.error.message}. Tente Novamente!`, {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
      });
    }
  }
  useEffect(() => {
    scrollToTop();
    trackParams('screen_view', 'account_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);
    getAccountInfo();
  }, []);

  const arrayCard = [
    {
      title: 'Meus Pacotes',
      myPlan,
    },
    { title: 'Veículos Ativos', vehicles_enabled },
  ];
  return (
    <>
      <Loading visibled={loading} />
      <ModalCe
        title={`${modal.route === 'Cancelar'}`}
        subTitle={
          <>
            <p>
              Ao fazer o cancelamento, os créditos do plano atual continuarão
              sendo disponibilizadas (liberados mensalmente) até o final do
              período já contratado, e sua renovação automática será cancelada.
            </p>
            <p className="m-0">Deseja realmente cancelar o seu plano?</p>
          </>
        }
        path={'/account'}
        titleButtonLeft={modal.route === '/cancelar' ? 'Não' : 'Cancelar'}
        titleButtonRigth={'Sim'}
        controllerModal={[modal.active, setModal]}
        size="lg"
        onClick={modal.route === '/cancelar' ? putAccountInfo : undefined}
      />

      <Header />
      <div className="header-page">
        <Row className="m-0">
          <Col lg={12} className="page-title page-title-account">
            <div className="auto-container">
              <h2 style={{ color: '#e0e0e0' }}>{header?.title}</h2>
              <ul className="bread-crumb clearfix">
                <li>
                  <a href="/">Home</a>
                </li>
                <li style={{ color: '#e0e0e0' }}>Conta</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <ContainerPage>
        <div className="auto-container">
          <div className="pt-4 pb-4" style={{ backgroundColor: '#F2F2F2' }}>
            <Navigation
              callback={(data: any) => {
                setDataProducty([data]);
              }}
              type={'conta'}
              qtdeOptions={2}
              firstSelected={0}
              responseProduct={arrayCard}
              noMessage
            />

            {dataProducty[0]?.myPlan ? (
              <>
                {dataProducty[0]?.myPlan?.table_data.length ? (
                  <>
                    <Row>
                      <Col lg={12} md={12} sm={12} className="pt-4">
                        <h3 className="text-center pt-4">
                          {dataProducty[0]?.myPlan?.section_title}
                        </h3>
                        <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                        <Table borderless size="sm" responsive="lg">
                          <thead>
                            <tr className="text-center">
                              {dataProducty[0]?.myPlan &&
                                Object.values(
                                  dataProducty[0]?.myPlan?.table_header
                                ).map((data: any, idx: number) => {
                                  return <th key={idx}>{data}</th>;
                                })}
                            </tr>
                          </thead>

                          <tbody>
                            {dataProducty[0]?.myPlan &&
                              dataProducty[0]?.myPlan?.table_data.map(
                                (data: any, idx: number) => {
                                  return (
                                    <tr className="text-center" key={idx}>
                                      {Object.values(data).map(
                                        (info_plan: any, idx: number) => {
                                          return <td key={idx}>{info_plan}</td>;
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                        <Container>
                          <Row className="justify-content-center">
                            {dataProducty[0]?.myPlan &&
                              dataProducty[0]?.myPlan?.actions.map(
                                (data: any, idx: number) => {
                                  if (String(data.title).includes('Comprar')) {
                                    data.skip_popup = true;
                                  }

                                  return (
                                    <Col
                                      sm={12}
                                      lg={4}
                                      className="pt-2"
                                      key={`id_${idx}`}
                                    >
                                      {data.skip_popup ? (
                                        <div className="text-center">
                                          <HashLink
                                            to="/#detalhes-plano"
                                            className="theme-btn btn-style-seven"
                                          >
                                            <span className="txt">
                                              {data?.title}
                                            </span>
                                          </HashLink>
                                        </div>
                                      ) : (
                                        <div
                                          className="theme-btn btn-style-one col-12"
                                          onClick={() => {
                                            setModal({
                                              active: true,
                                              route: `${data.route}`,
                                            });
                                            trackParams(
                                              data?.title === 'Upgrade'
                                                ? 'on_click_upgrade'
                                                : 'on_click_cancel_plan',
                                              location.pathname
                                            );
                                          }}
                                        >
                                          <span className="txt">
                                            {data?.title}
                                          </span>
                                        </div>
                                      )}
                                    </Col>
                                  );
                                }
                              )}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Container>
                    <Row>
                      <Col lg={12} md={12} sm={12} className="pt-4">
                        <h3 className="text-center pt-4">
                          {dataProducty[0]?.myPlan?.section_title}
                        </h3>
                        <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                        <p className="text-center m-0">
                          Você ainda não possui nenhum plano!
                        </p>
                      </Col>
                    </Row>
                  </Container>
                )}
              </>
            ) : dataProducty[0]?.vehicles_enabled ? (
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="pt-4"
                  id="veiculos-ativo"
                >
                  <div className="pt-4" style={{ backgroundColor: '#F2F2F2' }}>
                    <h3 className="text-center">
                      {dataProducty[0]?.vehicles_enabled?.title}
                    </h3>
                    <hr />
                    {dataProducty[0]?.vehicles_enabled?.table_data.length ? (
                      <Table
                        borderless
                        size="sm"
                        responsive="lg"
                        id="veiculos_ativos"
                      >
                        <thead>
                          <tr className="text-center">
                            {dataProducty[0]?.vehicles_enabled &&
                              Object.values(
                                dataProducty[0]?.vehicles_enabled?.table_header
                              ).map((data: any, idx: number) => {
                                return <th key={idx}>{data}</th>;
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {dataProducty[0]?.vehicles_enabled &&
                            dataProducty[0]?.vehicles_enabled?.table_data.map(
                              (data: any, idx: number) => {
                                return (
                                  <tr className="text-center" key={idx}>
                                    {Object.values(data).map(
                                      (
                                        info_vehicles_enabled: any,
                                        idx: number
                                      ) => {
                                        return (
                                          <td key={idx}>
                                            {info_vehicles_enabled}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    ) : (
                      <Container>
                        <Row>
                          <Col xl={12}>
                            <p className="text-center m-0">
                              Você ainda não possui nenhum veículo ativo!!
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Container>
                <Row>
                  <Col lg={12} md={12} sm={12} className="pt-4">
                    <h3 className="text-center pt-4">Erro</h3>
                    <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                    <p className="text-center m-0">
                      Não foi possível obter as informações da conta! Tente
                      novamente ou entre em contato com nosso suporte.
                    </p>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </ContainerPage>
      <Footer />
    </>
  );
};
export default Account;
