/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/named */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Card, { Focused } from 'react-credit-cards';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import Checkbox from '../../../components/custom-checkbox';
import Input from '../../../components/custom-input';
import { Box } from '../../../styles/box';
import { CardCE } from '../../../styles/card';
import { ProductContext } from '../../../contexts/product';
import Loading from '../../../components/custom-loading';
import { trackParams } from '../.././../network/manager-analytics';

interface PaymentProps {
  buttonTitle?: string;
  isMobile?: boolean;
  modalFunction?: () => null;
}

const CreditCardForm: React.FC<PaymentProps> = (props: PaymentProps) => {
  const { isMobile, modalFunction, buttonTitle } = props;

  const [check, setCheck] = useState<number>(0);

  const formRefs = useRef(null);
  const [inputNumber, setInputNumber] = useState<string>('');
  const [inputName, setInputName] = useState<string>('');
  const [inputCvc, setInputCvc] = useState<string>('');
  const [inputExpire, setInputExpire] = useState<string>('');
  const [cardFlag, setCardFlag] = useState<string>('');
  const [isFocused, setIsFocused] = useState<Focused>('number');
  const {
    handlePayment,
    setFormRef,
    handleTermsOfUse,
    setCheckboxEquals,
    isLoadingButtonProd,
    setFormSelected,
  } = useContext(ProductContext);

  async function handleSubmit() {
    handleTermsOfUse();
    handlePayment();
  }

  const handleInputNumber = (value: string) => {
    setInputNumber(value);
  };

  const handleInputNumberMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let mask = e.currentTarget.value;
    if (mask) {
      mask = mask.replace(/^(\d{2})(\d)/, '$1/$2');
      mask = mask.replace(/\D/g, '');
      mask = mask.replace(/^(\d{4})(\d)/g, '$1 $2');
      mask = mask.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
      mask = mask.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');

      setInputNumber(mask);
    }
  };

  const handleInputName = (value: string) => {
    setInputName(value);
  };

  const handleInputNameMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let mask = e.currentTarget.value;
    if (mask) {
      mask = mask.replace(/[^a-zà-úA-ZÀ-Ú ']/g, '');
    }

    setInputName(mask);
  };
  const handleInputExpire = (value: string) => {
    setInputExpire(value);
  };

  const handleInputExpireMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let mask = e.currentTarget.value;
    if (mask) {
      mask = mask.replace(/\D/g, '');
      mask = mask.replace(/^(\d{2})(\d)/, '$1/$2');
    }

    setInputExpire(mask);
  };
  const handleInputCvc = (value: string) => {
    setInputCvc(value);
  };
  const handleInputCvcMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let mask = e.currentTarget.value;
    if (mask) {
      mask = mask.replace(/\D/g, '');
      mask = mask.replace(/[^0-9]/g, '');
    }

    setInputCvc(mask);
  };

  const handleInputFocus = (e: any) => {
    setIsFocused(e.target.name);
  };

  const handleCallback = useCallback((issuer: any, isValid: boolean) => {
    setCardFlag(issuer.issuer);
  }, []);

  useEffect(() => {
    setFormSelected('credit-card');
    setFormRef(formRefs);
  }, []);

  return (
    <div className="shop-form">
      <Form ref={formRefs} onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Box className="col-md-12 col-xl-12">
            <CardCE>
              <Card
                cvc={inputCvc}
                expiry={inputExpire}
                name={inputName}
                number={inputNumber}
                callback={handleCallback}
                acceptedCards={[
                  'visa',
                  'mastercard',
                  'discover',
                  'amex',
                  'jcb',
                  'dinersclub',
                  'elo',
                  'aura',
                  'hipercard',
                ]}
                placeholders={{ name: 'Seu nome aqui' }}
                locale={{ valid: 'válido até' }}
                focused={isFocused}
              />
            </CardCE>
          </Box>
        </Row>
        <Row>
          <Col xl={10} className="p-0 m-auto">
            <Input
              label="Número do Cartão"
              colorLabel="#404040"
              name="creditCardNumber"
              type="text"
              inputMode="numeric"
              placeholder="•••• •••• •••• ••••"
              value={inputNumber}
              onKeyUp={(e) => {
                handleInputNumberMask(e);
              }}
              onChange={(e) => {
                handleInputNumber(e.target.value);
              }}
              onFocus={(e) => {
                handleInputFocus(e);
              }}
              mandatory
            />
          </Col>
          <Col xl={10} className="p-0 m-auto">
            <Input
              label="Nome"
              colorLabel="#404040"
              name="creditCardName"
              type="text"
              value={inputName}
              placeholder="Digite o nome do titular"
              onKeyUp={(e) => {
                handleInputNameMask(e);
              }}
              onChange={(e) => {
                handleInputName(e.target.value);
              }}
              onFocus={(e) => {
                handleInputFocus(e);
              }}
              mandatory
            />
          </Col>
          <Row className="justify-content-center m-0 p-0">
            <Col xl={6} className="p-0">
              <Input
                label="Data de Validade"
                colorLabel="#404040"
                name="expireData"
                type="text"
                value={inputExpire}
                inputMode="numeric"
                placeholder="00/0000"
                onKeyUp={(e) => {
                  handleInputExpireMask(e);
                }}
                onChange={(e) => {
                  handleInputExpire(e.target.value);
                }}
                onFocus={(e) => {
                  handleInputFocus(e);
                }}
                maxLength={7}
                mandatory
              />
            </Col>
            <Col xl={4} className="ps-3 pe-0" id="cvc">
              <Input
                label="CVV"
                colorLabel="#404040"
                name="creditCardCvc"
                type="text"
                placeholder="000"
                inputMode="numeric"
                value={inputCvc}
                onKeyUp={(e) => {
                  handleInputCvcMask(e);
                }}
                onChange={(e) => {
                  handleInputCvc(e.target.value);
                }}
                onFocus={(e) => {
                  handleInputFocus(e);
                }}
                maxLength={4}
                mandatory
              />
            </Col>
            <Col xl={10} className="pt-4 pb-3 ps-0 pe-0">
              <button
                disabled={isLoadingButtonProd}
                type="submit"
                className="theme-btn btn-style-one col-12"
              >
                <span className="txt">
                  {isLoadingButtonProd ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    buttonTitle || 'Comprar Agora'
                  )}
                </span>
              </button>
            </Col>
            {isMobile && (
              <Col xl={10} className="m-auto pe-0 ps-0">
                <Checkbox
                  noMargin
                  name="termos_uso"
                  onChange={(e: any) => setCheckboxEquals(e)}
                  label={
                    <>
                      Concordo com os{' '}
                      <a
                        href=""
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          modalFunction && modalFunction();
                        }}
                      >
                        Termos de uso
                      </a>
                    </>
                  }
                />
              </Col>
            )}
          </Row>
        </Row>
      </Form>
    </div>
  );
};

export default CreditCardForm;
