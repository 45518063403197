import styled from 'styled-components';
interface FieldPros {
  color?: string;
}
export const FieldLabel = styled.label<FieldPros>`
  display: block;
  line-height: 24px;
  color: ${({ color }) => (color ? color : '#e0e0e0')};
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
`;
