import { ValidationError } from 'yup';

interface ArrayErrors {
  [key: string]: string;
}

export default function getErrors(err: ValidationError): ArrayErrors {
  const validationErrors: ArrayErrors = {};
  err.inner.forEach((error: any) => {
    if (error && error.path) {
      validationErrors[error.path] = error.message;
    }
  });

  return validationErrors;
}
