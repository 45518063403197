import React from 'react';
import { Row, Col } from 'react-bootstrap';

import cielo from '../../assets/images/resource/cielo.jpg';
import selo from '../../assets/images/resource/10-anos2.png';
import visa from '../../assets/images/icons/Visa.png';
import jcb from '../../assets/images/icons/JCB.png';
import americanExp from '../../assets/images/icons/AmericanExpress.png';
import aura from '../../assets/images/icons/Aura.png';
import credicard from '../../assets/images/icons/Credicard.png';
import dinnersClub from '../../assets/images/icons/DinnersClub.png';
import discovery from '../../assets/images/icons/Discovery.png';
import elo from '../../assets/images/icons/Elo.png';
import hipercard from '../../assets/images/icons/hipercard.png';

import { InnerContainer, LogoCielo, Ten } from './styled';
import { useLocation, useParams } from 'react-router-dom';
const Selo: React.FC = () => {
  const location = useLocation();
  const { id } = useParams();

  return (
    <section className="ten-section" id="selo-contato">
      <div className="auto-container">
        <InnerContainer>
          {location.pathname === `/checkout/${id}/details` ? (
            <Row className="mb-3">
              <Col sm={12}>
                <h6 className="text-center pb-2">Bandeiras Aceitas</h6>
              </Col>
              <Col sm={4}>
                <Row>
                  <div id="imgs_bandeira">
                    <img src={visa} alt="Logo Visa" loading="lazy" />
                    <img src={credicard} alt="Logo Mastercard" loading="lazy" />
                    <img src={elo} alt="Logo Elo" loading="lazy" />
                  </div>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <div id="imgs_bandeira">
                    <img
                      src={americanExp}
                      alt="Logo AmericanExpress"
                      loading="lazy"
                    />
                    <img src={hipercard} alt="Logo Hipercard" loading="lazy" />
                    <img src={jcb} alt="Logo JCB" loading="lazy" />
                  </div>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <div id="imgs_bandeira">
                    <img src={aura} alt="Logo Aura" loading="lazy" />
                    <img src={discovery} alt="Logo Discovery" loading="lazy" />
                    <img
                      src={dinnersClub}
                      alt="Logo DinnersClub"
                      loading="lazy"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <LogoCielo>
            <Row>
              <Col>
                <div id="img-cielo">
                  <img src={cielo} alt="Logo Cielo" loading="lazy" />
                </div>
              </Col>
            </Row>
          </LogoCielo>
          <Ten className="selo">
            <hr className="w-100" />
            <Row style={{ paddingTop: 40, alignItems: 'center' }}>
              <Col lg={3} md={3} sm={3} className="img-column">
                <div className="ten-img">
                  <img src={selo} alt="Dez anos faaftech" loading="lazy" />
                </div>
              </Col>
              <Col lg={9} md={9} sm={9}>
                <p>
                  Conte com a segurança de um produto desenvolvido por uma
                  empresa especialista em tecnologia automotiva com mais de 10
                  anos de mercado e processo de produção certificado ISO 9001.
                </p>
              </Col>
            </Row>
          </Ten>
        </InnerContainer>
      </div>
    </section>
  );
};

export default Selo;
