import React, { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { PlansProvider } from './plans';
import { ProductProvider } from './product';
interface ChildrenContextData {
  children?: ReactNode;
}
const AppContext: React.FC<ChildrenContextData> = (
  props: ChildrenContextData
) => {
  const { children } = props;
  return (
    <AuthProvider>
      <ProductProvider>
        <PlansProvider>{children}</PlansProvider>
      </ProductProvider>
    </AuthProvider>
  );
};

export default AppContext;
