/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProductContext } from '../../contexts/product';
import { AuthContext } from '../../contexts/auth';
import ModalCe from '../custom-modal';
import { trackParams } from '../../network/manager-analytics';
interface Card {
  title: string;
  value: number;
  valueString: string;
  serviceInfos: [];
  actionButtons: [];
}
interface CardProps {
  dataCard?: any;
}

const CardPlans: React.FC<CardProps> = (props: CardProps) => {
  const { dataCard } = props;

  const useProductContext = useContext(ProductContext);
  const { user, setCompanyButton, dataMe } = useContext(AuthContext);

  const checkoutClick = () => {
    useProductContext.setLastSelectedProduct(-1);
  };
  const [modal, setModal] = useState({
    active: false,
    routeButtonLeft: '',
    routeButtonRight: '',
  });

  return (
    <>
      <ModalCe
        title="Já imaginou poder fazer demonstração para seus clientes sem custo?"
        subTitle="Com um plano CustomPro, além de poder fazer demonstrações para seus clientes sem custo, você ainda economiza no valor por carro, dentre outras vantagens."
        path={`/checkout/${modal.routeButtonRight}/details`}
        pathLeft={`/checkout/${modal.routeButtonLeft}/details`}
        titleButtonLeft="Continuar sem modo demonstração "
        titleButtonRigth="Mudar para CustomPro 5"
        controllerModal={[modal.active, setModal]}
        size="lg"
      />
      <div className="w-100 justify-content-center row m-0">
        {dataCard[0]?.products.length > 0
          ? dataCard[0]?.products?.map((dados: Card, index: number) => {
              return (
                <div
                  className="price-block col-xl-3 col-lg-4 col-md-6 col-sm-12"
                  key={`id_${index}`}
                >
                  <div
                    className="inner-box wow fadeInLeft animated"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: 'visible',
                      animationDuration: '1500ms',
                      animationDelay: '0ms',
                      animationName: 'fadeInLeft',
                    }}
                  >
                    <div className="upper-box">
                      <h4>
                        {dados.title}
                        <span>{dados.valueString}</span>
                      </h4>
                    </div>
                    <div className="lower-box">
                      <ul className="price-list">
                        {dados.serviceInfos.map(
                          (info: string, index: number) => {
                            return <li key={`id_${index}`}>{info}</li>;
                          }
                        )}
                      </ul>

                      {dados.actionButtons.map(
                        (info_button: any, index: number) => {
                          return dataCard[0].section === 'to_you' &&
                            dataMe?.offers?.custom_pro ? (
                            <div className="button-box" key={`id_${index}`}>
                              <div
                                className="theme-btn btn-style-one"
                                onClick={() => {
                                  setModal({
                                    active: true,
                                    routeButtonLeft: info_button.route,
                                    routeButtonRight: dataMe.offers?.route,
                                  });
                                }}
                              >
                                <span className="txt">{info_button.title}</span>
                              </div>
                            </div>
                          ) : (
                            <div className="button-box" key={`id_${index}`}>
                              <Link
                                to={`checkout/${info_button.route}/details`}
                                className="theme-btn btn-style-one"
                                onClick={() => {
                                  trackParams(
                                    'on_click_sign_now',
                                    location.pathname,
                                    [
                                      {
                                        propertie: 'selected_package',
                                        value: info_button.route,
                                      },
                                    ]
                                  );
                                  checkoutClick;
                                }}
                              >
                                <span className="txt">{info_button.title}</span>
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : dataCard[0]?.content &&
            dataCard[0]?.content?.messages?.map((data: any, index: number) => {
              return (
                <>
                  <Row key={`id_${index}`}>
                    <Col className="messsage-alert" xl={12}>
                      <h4>{data.title}</h4>
                      <p>{data.message}</p>
                      {dataCard[0]?.content?.actionButtons?.map(
                        (info_button: any, idx: number) => {
                          return (
                            <div className="button-box" key={`id_${idx}`}>
                              <Link
                                onClick={() => {
                                  setCompanyButton(true);
                                }}
                                to={`/sign-up${
                                  user ? info_button.route : '/login'
                                }`}
                                className="theme-btn btn-style-one"
                              >
                                <span className="txt">{info_button.title}</span>
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
      </div>
    </>
  );
};

export default CardPlans;
