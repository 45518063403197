/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import Navigation from '../../components/custom-navigation/index';
import Loading from '../../components/custom-loading';
import { scrollToTop } from '../../util/bibli';
import { ContainerPage } from '../../styles/container-page';
import SignIn from '../SignIn';
import ForgotPassword from '../ForgotPassword';
import SignUp from '../SignUp';
import FormPartner from '../FormPartner';
import { AuthContext } from '../../contexts/auth';
import back from '../../assets/images/background/pattern-2.png';
import { useNavigate } from 'react-router-dom';
import { trackParams } from '../../network/manager-analytics';

const SignUpPartner: React.FC = () => {
  const { user, token, setDataMe, getMe, authenticated } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const [formActive, setFormActive] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [dataProducty, setDataProducty] = useState<any>([]);
  const [check, setCheck] = useState<number>(0);

  function handleChange(newFormActive: React.SetStateAction<string>) {
    setFormActive(newFormActive);
  }

  useEffect(() => {
    scrollToTop();
    trackParams('screen_view', 'form_partner_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);
    if (user !== undefined) {
      setCheck(1);
      setDataProducty([arrayCard[1]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (token || authenticated?.authUserToken) {
      handleUser();
    }
  }, [token || authenticated?.authUserToken]);

  async function handleUser() {
    try {
      setLoading(true);

      const response = await getMe(token || authenticated?.authUserToken);
      if (response.data?.company?.partner_approved_on) {
        navigate('/partner');
      }
      setDataMe(response.data?.company);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
    }
  }
  const arrayCard = [
    {
      title: 'Login',
      component: (
        <SignIn
          onClick={handleChange}
          colorLabel="#404040"
          colorLabelFooter="#404040"
        />
      ),
    },
    { title: 'Cadastro', component: <FormPartner /> },
  ];
  return (
    <>
      <Loading visibled={loading} />
      <Header />
      <Row className="m-0 header-page">
        <Col lg={12} className="page-title page-title-partner">
          <div className="auto-container">
            <h2>Parceiro</h2>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Parceiro</li>
            </ul>
          </div>
        </Col>
      </Row>
      <ContainerPage style={{ backgroundImage: `url(${back})` }}>
        <div className="auto-container">
          <Row className="justify-content-center" id="box_cards">
            <Col lg={5} md={12} sm={12} id="pagamento-plano">
              <div
                className={`widget-heading return-widget position-absolute float-left ${
                  formActive === '' ? 'd-none' : 'd-block'
                } p-2`}
                style={{ zIndex: '9999' }}
              >
                <a
                  onClick={() => {
                    setFormActive('');
                  }}
                  className="return-side-widget"
                  style={{ color: '#4b90cf', fontSize: '18px' }}
                >
                  <span className="fa fa-arrow-left" id="arrow-back" />
                </a>
              </div>
              <div
                className="shop-order-box"
                style={{ backgroundColor: '#F2F2F2' }}
              >
                <Navigation
                  callback={(data: any) => {
                    setDataProducty([data]);
                  }}
                  responseProduct={arrayCard}
                  qtdeOptions={arrayCard.length}
                  firstSelected={check}
                  type="pagamento"
                  noMessage
                />
                <div className="row m-0 justify-content-center">
                  {dataProducty?.title === 'Login' ||
                  dataProducty.length === 0 ? (
                    <div className="col-10">
                      {formActive === '' ? (
                        <SignIn
                          onClick={handleChange}
                          colorLabel="#404040"
                          colorLabelFooter="#404040"
                        />
                      ) : formActive === 'forgot-password' ? (
                        <ForgotPassword colorLabel="#404040" />
                      ) : (
                        <SignUp colorLabel="#404040" />
                      )}
                    </div>
                  ) : (
                    <div className="col-10">{dataProducty[0].component}</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ContainerPage>
      <Footer />
    </>
  );
};
export default SignUpPartner;
