import styled from 'styled-components';
interface FooterLoginProps {
  color?: string;
}
export const FooterLogin = styled.div<FooterLoginProps>`
  display: flex;
  justify-content: space-between;
  /* .forgotPassword {
    color: ${({ color }) => (color ? color : '#ffffff')} !important;
    &:hover {
      color: #0056b3 !important;
    }
  } */
`;
