import React from 'react';

const Terms: React.FC = () => {
  return (
    <>
      <p>
        1 - Para utilização do App é necessário um dispositivo CustomEasy
        vendido separadamente e um crédito de veículo já ativado ou disponível.
      </p>
      <p>
        2 - Os créditos são contabilizados por veículo, sendo este identificado
        pelo número de chassi entre outras informações especificas.
      </p>
      <p>
        3 - Uma vez ativado um veículo, seu acesso ao CustomEasy permanece
        valido por 5 anos.
      </p>
      <p>
        4 - As funcionalidades disponíveis são detectadas automaticamente e
        variam conforme o modelo, versão e equipamentos do veículo. Portanto,
        nem todas as opções listadas no site e outros meios podem estar
        disponíveis para o seu veículo.
      </p>
      <p>
        5 - Para o funcionamento do App é necessária uma conexão com a internet,
        o uso de dados pode ser cobrado por sua operadora, sendo assim consulte
        sua operadora para mais detalhes sobre planos e valores.
      </p>
      <p>
        6 - A qualidade da conexão de internet pode afetar a velocidade e
        performance do App.
      </p>
      <p>
        7 - Para prestação dos serviços são utilizados infraestrutura própria e
        de terceiros (Servidores, Hospedagem, DataBases, etc) que estão sujeitos
        a manutenção, migrações e outros fatores que podem afetar a
        disponibilidade dos serviços.
      </p>
      <p>
        8 - Utilizações indevidas - No caso de detecção de tentativas de
        utilização indevida, fora dos termos contratados ou mesmo tentativa de
        fraude em mecanismos de funcionamento do Hardware (Dispositivo
        CustomEasy) ou no App, tanto os créditos quanto o Hardware estão
        sujeitos a bloqueio e cancelamentos definitivos.
      </p>
      <p>
        9 - Os valores e condições podem variar conforme a plataforma de
        contratação e podem ser também alterados mediante comunicação por e-mail
        ou outros meios.
      </p>
      <p>
        10 - A compatibilidade do CustomEasy é baseada na tecnologia atual
        presente nas montadoras das Marcas e Modelos listados como compatíveis,
        eventuais mudanças de tecnologia, implementação de tecnologias de
        bloqueio de acesso, como SGW (Security Gateway) podem temporariamente ou
        indefinidamente afetar ou em casos extremos impossibilitar o uso do
        CustomEasy.
      </p>
      <p>
        11 - Propriedade intelectual - A Faaftech detém os direitos de
        Copyright, incluindo todos os textos, imagens, vídeos, e elementos
        gráficos do site e do App, sendo proibidas sua cópia e utilização sem
        autorização por expresso da Faaftech.
      </p>
    </>
  );
};

export default Terms;
