/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/Faaftech.png';
import { AuthContext } from '../../contexts/auth';
import ModalCe from '../custom-modal';
import { HashLink } from 'react-router-hash-link';
import { scrollToTop } from '../../util/bibli';
import { trackParams } from '../../network/manager-analytics';

const Header: React.FC = () => {
  const location = useLocation();
  const { user, dataMe } = useContext(AuthContext);
  const [modalHeader, setModalHeader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const company = localStorage.getItem('@Customeasy:Company');
  const company_aprov = company && JSON.parse(company);

  return (
    <>
      {modalHeader ? (
        <ModalCe
          title="Sair"
          subTitle={<p className="m-0">Deseja realmente sair?</p>}
          path="/sign-out"
          titleButtonLeft="Não"
          titleButtonRigth="Sim"
          controllerModal={[modalHeader, setModalHeader]}
        />
      ) : (
        <></>
      )}

      <>
        <Navbar
          fixed="top"
          bg="light"
          expand="lg"
          className="pt-0 pb-0 main-header"
          expanded={expanded}
        >
          <Container>
            <div className="pull-left logo-box">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="Logo Faaftech" />
                </a>
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <Navbar.Brand className="p-0 ms-3 me-0">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="me-0 "
                  onClick={() => setExpanded((prev) => !prev)}
                />
              </Navbar.Brand>
              {!user ? (
                <div
                  className="icon-login align-items-center  clearfix"
                  style={{ opacity: location.pathname === '/' ? 1 : 0 }}
                >
                  <div>
                    <Link
                      to={`/sign-up/login`}
                      className="login-box-btn nav-btn navSidebar-button"
                    >
                      <span className="fa fa-user" />
                    </Link>
                  </div>
                </div>
              ) : (
                <div
                  className="icon-login align-items-center clearfix"
                  id="logout"
                >
                  <div
                    className="exit login-box-btn nav-btn navSidebar-button"
                    onClick={() => setModalHeader(true)}
                  >
                    <span className="fa fa-sign-out" />
                  </div>
                </div>
              )}
            </div>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="navigation">
                <Link
                  title="Home"
                  to="/"
                  className="link-header"
                  onClick={() => {
                    scrollToTop();
                    setExpanded(false);
                    trackParams('on_click_navbar', location.pathname, [
                      {
                        propertie: 'Menu',
                        value: 'Home',
                      },
                    ]);
                  }}
                >
                  Home
                </Link>
                <HashLink
                  to="/#planos"
                  className="link-header"
                  onClick={() => {
                    setExpanded(false);
                    trackParams('on_click_navbar', location.pathname, [
                      {
                        propertie: 'Menu',
                        value: 'Pacotes',
                      },
                    ]);
                  }}
                >
                  Pacotes
                </HashLink>
                {company_aprov && company_aprov?.partner_approved_on && (
                  <Link
                    to="/partner"
                    className="link-header"
                    onClick={() => {
                      trackParams('on_click_navbar', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Parceiro',
                        },
                      ]);
                    }}
                  >
                    Parceiro
                  </Link>
                )}
                {user && (
                  <Link
                    to="/account"
                    className="link-header"
                    onClick={() => {
                      trackParams('on_click_navbar', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Conta',
                        },
                      ]);
                    }}
                  >
                    Conta
                  </Link>
                )}
                <Link
                  to="/contact"
                  state={{ backgroundLocation: location }}
                  className="link-header"
                  onClick={() => {
                    setExpanded(false);
                    trackParams('on_click_navbar', location.pathname, [
                      {
                        propertie: 'Menu',
                        value: 'Contato',
                      },
                    ]);
                  }}
                >
                  Contato
                </Link>
              </Nav>
            </Navbar.Collapse>
            {user ? (
              <div className="usuario-box text-left">
                <p className="m-0">Bem vindo(a), {user.full_name}</p>
                <div
                  className="exit"
                  onClick={() => {
                    setModalHeader(true);
                    trackParams('on_click_navbar', location.pathname, [
                      {
                        propertie: 'Menu',
                        value: 'Sair',
                      },
                    ]);
                  }}
                >
                  Sair
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              className="icon-login-menu align-items-center ms-3 clearfix"
              style={{ opacity: location.pathname === '/' ? 1 : 0 }}
            >
              {!user ? (
                <div>
                  <Link
                    to={`/sign-up/login`}
                    className="login-box-btn nav-btn navSidebar-button"
                  >
                    <span className="fa fa-user" />
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Container>
        </Navbar>
      </>
    </>
  );
};

export default Header;
