/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import Input from '../../components/custom-input';
import Loading from '../../components/custom-loading';
import Checkbox from '../../components/custom-checkbox';
import ModalCe from '../../components/custom-modal';
import { Form } from '@unform/web';
import { Col, Row } from 'react-bootstrap';
import Terms from '../Terms';
import { AuthContext } from '../../contexts/auth';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import { trackParams } from '../../network/manager-analytics';

interface SignUpProps {
  colorLabel?: string;
}

interface dataTrack {
  propertie: string;
  value: string | number;
}

const SignUp: React.FC<SignUpProps> = (props: SignUpProps) => {
  const { colorLabel } = props;
  const formRef = React.useRef<FormHandles>(null);
  const [modal, setModal] = useState({ active: false });
  const { signUp, loadingAuth, setFormRef, setLoadingAuth } =
    useContext(AuthContext);

  async function handleSubmit() {
    signUp();
  }
  useEffect(() => {
    setLoadingAuth(false);
    setFormRef(formRef);
  }, []);
  return (
    <>
      <ModalCe
        size="lg"
        title="Termos de uso CustomEasy"
        subTitle={<Terms />}
        titleButtonLeft="Concordo"
        controllerModal={[modal.active, setModal]}
      />
      <div className="shop-form form-signUp">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <h4 className="signUp text-center" style={{ color: '#4b90cf' }}>
              Criar Conta
            </h4>
            <Col xl={10} className="m-auto p-0">
              <Input
                type="text"
                name="name"
                placeholder="Nome"
                label="Nome"
                colorLabel={colorLabel}
              />
            </Col>
            <Col xl={10} className="m-auto p-0">
              <Input
                type="text"
                name="email"
                placeholder="email@dominio.com.br"
                label="Email"
                colorLabel={colorLabel}
              />
            </Col>
            <Col xl={10} className="m-auto p-0">
              <Input
                type="password"
                name="password"
                placeholder="***********"
                label="Senha"
                colorLabel={colorLabel}
                style={{ marginBottom: '1rem' }}
              />
            </Col>
            <Col xl={10} className="m-auto pt-2 pe-0 ps-0">
              <Checkbox
                noMargin
                name="politica_privacidade"
                labelColor={colorLabel}
                label={
                  <>
                    Concordo com os{' '}
                    <a
                      href=""
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        setModal({ active: true });
                      }}
                    >
                      termos de uso
                    </a>{' '}
                    e{' '}
                    <a
                      title="Política de Privacidade"
                      href="https://www.faaftech.com/politica/politica-customeasy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      política de privacidade
                    </a>
                  </>
                }
              />
            </Col>

            <Col
              xl={10}
              style={{ padding: 0, paddingTop: 10 }}
              className="m-auto"
            >
              <Input
                type="text"
                name="code_refer"
                placeholder="Tem um código de indicação?"
                // label="Código de indicação"
                colorLabel={colorLabel}
              />
            </Col>

            <Col xl={10} className="m-auto p-0">
              <div className="d-flex justify-content-center btn-modal">
                <button
                  type="submit"
                  className="theme-btn btn-style-one col-12"
                  disabled={loadingAuth}
                  onClick={() => {
                    const dataForm: any = formRef.current?.getData();

                    const dataTrack: dataTrack[] = [];

                    Object.keys(dataForm).forEach((key) => {
                      if (key != 'password') {
                        dataTrack.push({
                          propertie: key,
                          value: dataForm[key],
                        });
                      }
                    });

                    trackParams(
                      'on_click_register_account',
                      location.pathname,
                      dataTrack
                    );
                  }}
                >
                  <span className="txt">
                    {loadingAuth ? (
                      <div
                        style={{
                          height: 30,
                        }}
                      >
                        <Loading loading_button />
                      </div>
                    ) : (
                      'Salvar'
                    )}
                  </span>
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SignUp;
