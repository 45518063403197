/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import api from '../services/api';
import { AuthContext } from '../contexts/auth';

interface PlansContextData {
  plans: Function;
  data: any;
  responseProduct: any;
  firstCheck?: number;
  setFirstCheck?: any;
  dataProduct: any;
  setDataProduct: any;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  change: boolean;
  setChange: Dispatch<SetStateAction<boolean>>;
}
interface AuthContextData {
  children?: ReactNode;
}

export const PlansContext = createContext<PlansContextData>(
  {} as PlansContextData
);

const PlansProvider: React.FC<AuthContextData> = (props: AuthContextData) => {
  const { children } = props;
  const { authenticated, getMe } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [responseProduct, setResponseProduct] = useState<any>(null);
  const [firstCheck, setFirstCheck] = useState<number>();
  const [dataProduct, setDataProduct] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [change, setChange] = useState<boolean>(true);

  async function plans(AuthUserToken?: string) {
    try {
      const headers = {
        Authorization: '',
      };
      if (AuthUserToken || authenticated?.authUserToken) {
        headers.Authorization = `Bearer ${
          AuthUserToken || authenticated?.authUserToken
        }`;

        await getMe(AuthUserToken || authenticated?.authUserToken);
      }

      await api
        .get('/products/catalog', {
          headers,
        })
        .then(async (response) => {
          setData(response.data);
          const dados: {
            products: any;
            content: any;
            title: any;
            section: any;
          }[] = [];
          response.data.forEach((data: any) => {
            dados.push({
              title: data.sectionTitle,
              products: data.products,
              content: data.content,
              section: data.section,
            });
          });
          setResponseProduct(dados);

          const produtos = response.data.filter(function (data: any) {
            if (data.content) setChange((prev: any) => !prev);
            return data.selected;
          });
          setDataProduct(produtos);
          const firstSelect = response.data.findIndex(
            (i: { selected: boolean }) => i.selected === true
          );
          setFirstCheck(firstSelect);
          if (location.search) {
            if (location.hash === '#detalhes-plano') {
              window.location.href = `${location.search}#detalhes-plano`;
            }
            if (location.hash === '#planos') {
              window.location.href = `${location.search}#planos`;
            }
          } else {
            if (location.hash === '#detalhes-plano') {
              window.location.href = '/#detalhes-plano';
            }
            if (location.hash === '#planos') {
              window.location.href = '/#planos';
            }
          }

          setLoading(false);
        });
    } catch (err: any) {
      setLoading(false);
      console.log(err);
      console.error('ops! ocorreu um erro' + err);
    }
  }
  return (
    <PlansContext.Provider
      value={{
        plans,
        data,
        responseProduct,
        firstCheck,
        dataProduct,
        loading,
        setDataProduct,
        setFirstCheck,
        setLoading,
        change,
        setChange,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

export { PlansProvider };
