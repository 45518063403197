import React, { useContext, useState } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { PlansContext } from '../../../contexts/plans';

import img from '../../../assets/images/resource/service-1.png';
import backgroundWhite from '../../../assets/images/background/pattern-2.png';
import icon1 from '../../../assets/images/icons/icon1.png';
import icon2 from '../../../assets/images/icons/icon2.png';
import icon3 from '../../../assets/images/icons/icon3.png';
import icon4 from '../../../assets/images/icons/icon4.png';
import jsonToYou from '../../../assets/json/animationToYou.json';
import jsonCustomPro from '../../../assets/json/checking-car-details.json';
import { trackParams } from '../../../network/manager-analytics';

const About: React.FC = () => {
  const { data, setDataProduct, setFirstCheck, setChange } =
    useContext(PlansContext);
  return (
    <div className="dashboard">
      <section
        className="services-section"
        style={{ backgroundImage: `url(${backgroundWhite})` }}
      >
        <div className="container">
          <div className="lower-section">
            <div className="row clearfix">
              <div className="image-column col-lg-6 col-md-12 col-sm-12 ">
                <div
                  className="inner-column titlt"
                  data-tilt=""
                  data-tilt-max="3"
                  style={{
                    willChange: 'transform',
                    transform: 'perspective(300px) rotateX(0deg) rotateY(0deg)',
                  }}
                >
                  <div className="image">
                    <img src={img} alt="Aplicatico CustomEasy" loading="lazy" />
                  </div>
                </div>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12 d-flex align-items-center text-left">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Pacotes de créditos CustomEasy</div>
                    <h2>A personalização em suas mãos</h2>
                  </div>
                  <div className="bold-text">
                    Acesso contínuo às mais novas funcionalidades e
                    personalizações para mais carros
                  </div>
                  <div className="text">
                    Com os pacotes de créditos CustomEasy você aproveita o
                    máximo do seu CustomEasy e leva o mundo da personalização
                    para muitos outros carros, pagando apenas o valor do crédito
                    por veículo
                  </div>
                  {/* <div className="text">Para usar a sua assinatura no App é necessário ter um dispositivo
                                    CustomEasy, se voce já tem o seu, você está no lugar certo, mas caso ainda não
                                    tenha, aproveite para conhecer o dispositivo.</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing-section">
        <div className="container">
          <h2 className="pb-1 text-center" style={{ color: '#e0e0e0' }}>
            Como funciona?
          </h2>
          <h4 className="pb-5 text-center" style={{ color: '#e0e0e0' }}>
            Para ter acesso a todas as vantagens do CustomEasy é muito simples.
          </h4>
          <div className="row">
            <div className="col-lg-4">
              <div className="text-center">
                <img
                  src={icon1}
                  alt="Revendedor"
                  width="100px"
                  height="100px"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-lg-1">
                  <h2 style={{ color: '#4b8fce' }}>1</h2>
                </div>
                <div className="col-lg-10">
                  <p className="text-center" style={{ color: '#e0e0e0' }}>
                    Adquira seu dispositivo CustomEasy em um revendedor
                    autorizado.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="text-center">
                <img
                  src={icon3}
                  alt="Porta OBD-II"
                  width="100px"
                  height="100px"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-lg-1">
                  <h2 style={{ color: '#4b8fce' }}>2</h2>
                </div>
                <div className="col-lg-10">
                  <p className="text-center" style={{ color: '#e0e0e0' }}>
                    Conecte o dispositivo na porta OBD-II do carro
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-center">
                <img
                  src={icon4}
                  alt="Personalize"
                  width="100px"
                  height="100px"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-lg-1">
                  <h2 style={{ color: '#4b8fce' }}>3</h2>
                </div>
                <div className="col-lg-10">
                  <p className="text-center" style={{ color: '#e0e0e0' }}>
                    Personalize o carro com as suas preferências.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pt-5">
            <a
              href="https://www.faaftech.com/produtos/obd-tool/customeasy/"
              className="theme-btn btn-style-one"
              style={{ textTransform: 'uppercase' }}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackParams(
                  'on_click_know_customeasy_device',
                  location.pathname
                );
              }}
            >
              <span className="txt">Conhecer o CustomEasy</span>
            </a>
          </div>
        </div>
      </section>
      <section
        className="services-section"
        style={{ backgroundImage: `url(${backgroundWhite})` }}
        id="planos"
      >
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix justify-content-center">
              <div className="service-block active col-lg-5 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInRight animated"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: 'visible',
                    animationDuration: '1500ms',
                    animationDelay: '0ms',
                    animationName: 'fadeInUp',
                  }}
                >
                  {/* <span className="border-one" /> */}
                  {/* <span className="border-two" /> */}
                  <h4>
                    <a className="services-pro" href="#detalhes-plano">
                      Pacotes perfeitos para sua empresa
                    </a>
                  </h4>
                  <div className="text">
                    Agora para garantir todas as vantagens do CustomEasy para
                    seus clientes, e ainda aumentar a lucratividade do seu
                    negócio, temos pacotes para o tamanho da sua necessidade.
                  </div>
                  <div className="icon">
                    <Player
                      className="animationPlan"
                      autoplay
                      loop
                      src={jsonCustomPro}
                    >
                      <Controls
                        visible={false}
                        buttons={['play', 'repeat', 'frame', 'debug']}
                      />
                    </Player>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
