/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/named */
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import Navigation from '../../components/custom-navigation';

import CreditCardForm from './forms/creditCard';
import PixForm from './forms/pixForm';
import BankSlipForm from './forms/bankSlip';

interface PaymentProps {
  buttonTitle?: string;
  isMobile?: boolean;
  modalFunction?: () => null;
  isLoading?: boolean;
}

const Payment: React.FC<PaymentProps> = (props: PaymentProps) => {
  const [check, setCheck] = useState<number>(0);
  const [disableAll, setDisableAll] = useState<boolean>(false);

  const { isMobile, isLoading } = props;

  const getForm = () => {
    switch (check) {
      case 0:
        return <CreditCardForm isMobile={isMobile} />;

      case 1:
        return (
          <PixForm
            callback={(param) => {
              setDisableAll(param);
            }}
            isMobile={isLoading}
          />
        );
      case 2:
        return (
          <BankSlipForm
            callback={(param) => {
              setDisableAll(param);
            }}
            isMobile={isMobile}
          />
        );
    }
  };

  return (
    <>
      <div>
        <p style={{ textAlign: 'center', fontSize: 20, marginBottom: -15 }}>
          Selecione a forma de pagamento
        </p>
      </div>
      <Navigation
        disableAll={disableAll}
        callback={(data: any, index: number) => {
          setCheck(index);
        }}
        responseProduct={[
          {
            title: 'Cartão',
            value: 'credit-card',
            icon: (
              <i
                className="fa-solid fa-credit-card"
                style={{ marginRight: 8 }}
              />
            ),
          },
          {
            title: 'PIX',
            value: 'bank-transfer',
            icon: <i className="fa-brands fa-pix" style={{ marginRight: 8 }} />,
          },

          // {
          //   title: 'Boleto',
          //   value: 'bank-slip',
          //   icon: <i className="fa-solid fa-barcode" />,
          // },
        ]}
        qtdeOptions={2}
        firstSelected={check}
        type="default"
      />
      {getForm()}
    </>
  );
};

export default Payment;
