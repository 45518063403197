import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Input from '../../components/custom-input';
import { Form } from '@unform/web';
import { FieldLabel } from '../../styles/field-label';
import api from '../../services/api';
import * as Yup from 'yup';
import { errorYup, cnpjMask, validarCNPJ } from '../../util/bibli';
import { toast } from 'react-toastify';
import { Error } from '../../styles/error';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import { AuthContext } from '../../contexts/auth';
import { useLocation } from 'react-router-dom';
import { PlansContext } from '../../contexts/plans';
import axios from 'axios';
import { trackParams } from '../../network/manager-analytics';

interface Documents {
  document_type: string;
  value: string;
}

interface SignUpPartnerData {
  social_name: string;
  fantasy_name: string;
  national_company_number: string;
  local_company_number: string;
  international_company_number: string;
  partner_subscribed_on: string;
  partner_approved_on: string;
  street: string;
  number: string;
  complement: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
  documents: [Documents];
}
interface FormPartnerProps {
  colorLabel?: string;
  callback?: (value: string) => string;
}
const FormPartner: React.FC<FormPartnerProps> = (props: FormPartnerProps) => {
  const { colorLabel, callback } = props;

  const formRef = React.useRef<FormHandles>(null);
  const [uf, setUf] = useState('');
  const [errSelect, setErrSelect] = useState(false);
  const { authenticated, setLoadingAuth, dataMe } = useContext(AuthContext);
  const [dataCompany, setDataCompany] = useState<SignUpPartnerData>();

  const { plans } = useContext(PlansContext);
  const location = useLocation();
  const [values, setValues] = useState('');

  const inputChange = (e: any) => {
    const { value } = e.target;
    setValues(value);
  };
  const [addressState, setAddressState] = useState({
    zipcode: '',
    street: '',
    city: '',
  });
  const states = [
    { value: '', label: '' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  useEffect(() => {
    setDataCompany(dataMe);
  }, [dataMe]);

  const handleCepInput = async (value: string) => {
    if (value.length > 7) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      const cep = response.data.cep.replace(/[^\d]+/g, '');
      setAddressState({
        zipcode: cep,
        street: response.data.logradouro,
        city: response.data.localidade,
      });
      setUf(response.data.uf);
      setErrSelect(false);
      if (response.data.logradouro)
        formRef.current?.setFieldError('street', '');
      if (response.data.localidade) formRef.current?.setFieldError('city', '');
    }
  };
  async function handleSubmit(dataForm: SignUpPartnerData) {
    try {
      let schema;
      if (uf === '') {
        setErrSelect(true);
      }
      if (location.pathname === '/sign-up/complete-company') {
        schema = Yup.object().shape({
          social_name: Yup.string()
            .required('Razão social é obrigatório')
            .trim(),
          national_company_number: Yup.string()
            .required('CNPJ é obrigatório')
            .test({
              message: 'CNPJ informado é inválido',
              test: (value) => validarCNPJ(value),
            })
            .trim(),
        });
      } else {
        schema = Yup.object().shape({
          social_name: Yup.string()
            .required('Razão social é obrigatório')
            .trim(),
          national_company_number: Yup.string()
            .required('CNPJ é obrigatório')
            .test({
              message: 'CNPJ informado é inválido',
              test: (value) => validarCNPJ(value),
            })
            .trim(),
          local_company_number: Yup.string()
            .required('Inscrição municipal é obrigatório')
            .trim(),

          street: Yup.string().required('Cidade é obrigatória').trim(),
          city: Yup.string().required('Cidade é obrigatória').trim(),
          zipcode: Yup.string()
            .required('CEP é obrigatório')
            .test(
              'len',
              'CEP deve ter exatamente 8 caracteres',
              (val: any) => val.length === 8
            )
            .trim(),
        });
      }
      await schema.validate(dataForm, {
        abortEarly: false,
      });
      const cnpj = dataForm.national_company_number.replace(/[^\d]+/g, '');
      await api.post(
        '/company/create',
        location.pathname === '/sign-up/complete-company'
          ? {
              social_name: dataForm.social_name,
              national_company_number: cnpj,
            }
          : uf
          ? {
              social_name: dataForm.social_name,
              fantasy_name: dataForm.fantasy_name,
              international_company_number:
                dataForm.international_company_number,
              partner_subscribed_on: true,
              national_company_number: cnpj,
              local_company_number: dataForm.local_company_number,
              address: {
                type: 'comercial',
                street: dataForm.street,
                number: dataForm.number,
                complement: dataForm.complement,
                zipcode: dataForm.zipcode,
                city: dataForm.city,
                state: uf,
                country: 'BR',
              },
            }
          : {},
        {
          headers: {
            Authorization: `Bearer ${authenticated?.authUserToken}`,
          },
        }
      );
      if (location.pathname === '/sign-up/complete-company') {
        callback && callback('closed');
        toast.success('Cadastrado com sucesso!', {
          autoClose: 2000,
          position: 'top-right',
          theme: 'colored',
        });
        await plans();
        window.location.href = '/#detalhes-plano';
      } else {
        toast.success('Cadastrado realizado com sucesso!', {
          autoClose: 1000,
          position: 'top-center',
          theme: 'colored',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
    } catch (err: any) {
      setLoadingAuth(false);
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        if (!uf) {
          toast.error('É necessário informar o estado!', {
            autoClose: 2000,
            position: 'top-center',
            theme: 'colored',
          });
        } else {
          toast.error(
            `${err ? err : 'Erro'}. Entre em contato com nosso suporte!`,
            {
              autoClose: 5000,
              position: 'top-center',
              theme: 'colored',
            }
          );
        }
      }
    }
  }
  return (
    <>
      <div className="shop-form ">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row className="m-0">
            {location.pathname === '/sign-up/complete-company' ? (
              <>
                <h4 className="signUp text-center" style={{ color: '#4b90cf' }}>
                  Dados da empresa
                </h4>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="national_company_number"
                    placeholder="CNPJ"
                    label="CNPJ"
                    colorLabel={colorLabel}
                    value={cnpjMask(values)}
                    onChange={inputChange}
                  />
                </Col>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="social_name"
                    placeholder="Razão Social"
                    label="Razão Social"
                    colorLabel={colorLabel}
                  />
                </Col>
              </>
            ) : dataCompany && dataCompany?.partner_subscribed_on ? (
              <>
                <p className="text-center">
                  Seu cadastro de parceiro está em análise...
                </p>
              </>
            ) : (
              <>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="national_company_number"
                    placeholder="CNPJ"
                    label="CNPJ"
                    disabled={
                      dataCompany?.documents && dataCompany?.documents[0].value
                        ? true
                        : false
                    }
                    value={
                      cnpjMask(values) ||
                      cnpjMask(
                        dataCompany?.documents &&
                          dataCompany?.documents[0].value
                      )
                    }
                    onChange={inputChange}
                    mandatory
                  />
                </Col>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="social_name"
                    placeholder="Razão Social"
                    label="Razão Social"
                    defaultValue={`${dataCompany?.social_name || ''}`}
                    disabled={dataCompany?.social_name ? true : false}
                    mandatory
                  />
                </Col>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="fantasy_name"
                    placeholder="Nome Fantasia"
                    label="Nome Fantasia"
                  />
                </Col>
                <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="local_company_number"
                    placeholder="Inscrição Municipal"
                    label="Inscrição Municipal"
                    mandatory
                  />
                </Col>
                {/* <Col xl={10} className="m-auto p-0">
                  <Input
                    type="text"
                    name="insc_internacional"
                    placeholder="Inscrição Internacional"
                    label="Inscrição Internacional"
                  />
                </Col> */}
                <Col xl={10} className="p-0 m-auto">
                  <Input
                    type="text"
                    name="zipcode"
                    placeholder="00000-000"
                    label="CEP"
                    mandatory
                    defaultValue={addressState.zipcode}
                    onChange={(val) => {
                      handleCepInput(val.target.value);
                    }}
                    maxLength={8}
                  />
                </Col>
                <Col xl={10} className="p-0 m-auto">
                  <Input
                    type="text"
                    name="street"
                    placeholder="Rua, Avenida, Via..."
                    label="Endereço"
                    defaultValue={addressState.street}
                    mandatory
                  />
                </Col>
                <Row className="justify-content-center m-0 p-0">
                  <Col xl={3} className="p-0 numero">
                    <Input
                      type="text"
                      name="number"
                      placeholder="Nº"
                      label="Número"
                    />
                  </Col>
                  <Col xl={7} className="ps-3 pe-0 complemento">
                    <Input
                      type="text"
                      name="complement"
                      placeholder="Quadra, Lote, Casa, Apto..."
                      label="Complemento"
                    />
                  </Col>
                </Row>

                <Row className="justify-content-center m-0 p-0">
                  <Col xl={7} className=" p-0">
                    <Input
                      type="text"
                      name="city"
                      placeholder="Cidade"
                      label="Cidade"
                      defaultValue={addressState.city}
                      mandatory
                    />
                  </Col>
                  <Col xl={3} className="ps-3 pe-0 estado">
                    <FieldLabel htmlFor="state" color="#404040">
                      Estado
                      <sup>
                        <span style={{ color: '#c00' }}> *</span>
                      </sup>
                    </FieldLabel>
                    <select
                      name="state"
                      id="state"
                      value={uf}
                      onChange={(e) => {
                        setUf(e.target.value);
                        setErrSelect(false);
                      }}
                    >
                      {states.map((info: any, index: number) => {
                        return (
                          <option value={info.value} key={`id_${index}`}>
                            {info.value}
                          </option>
                        );
                      })}
                    </select>
                    {errSelect ? (
                      <Error className="invalid-feedback d-block m-0 bold-600">
                        Estado é obrigatório
                      </Error>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Col xl={10} className="m-auto p-0">
              <div
                className={`${
                  dataCompany?.partner_subscribed_on ? 'd-none' : 'd-flex'
                } justify-content-center btn-modal`}
              >
                <button
                  type="submit"
                  className="theme-btn btn-style-one col-12"
                  onClick={() => {
                    trackParams('on_click_register_partner', location.pathname);
                  }}
                >
                  <span className="txt">Salvar</span>
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormPartner;
