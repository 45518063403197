import styled from 'styled-components';
interface ToggleProps {
  left?: string;
  width?: string;
  margin?: string;
}

interface SeparatorProps {
  left?: string;
}

export const RadioContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  input[type='radio']:checked + label {
    color: white;
  }
  input[type='radio']:disabled + label {
    color: #cecece;
  }
  /* .ce-disabled {
    color: rgb(0 0 0 / 33%);
  } */
`;
export const Label = styled.label`
  font-size: 17px;
  font-weight: 600;
  line-height: 37px;
  margin: 0;
  border-radius: 999px;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
  z-index: 1;
  width: 100%;
  color: #777;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const ToggleSlider = styled.div<ToggleProps>`
  background: hsl(209deg 58% 55%);
  height: 38px;
  position: absolute;
  border-radius: 999px;
  transition: left 0.3s ease;
  left: ${({ left }) => (left ? left : 0)};
  width: ${(props) => props.width};
  margin: ${({ margin }) => (margin ? margin : '0px -2px')};
`;

export const Separator = styled.div<SeparatorProps>`
  left: ${(props) => props.left};
  width: 1px;
  height: 36px;
  position: absolute;
`;
