import React, { useCallback, useState } from 'react';
import Input from '../../components/custom-input';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { errorYup, translate } from '../../util/bibli';
import Loading from '../../components/custom-loading';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { FieldLabel } from '../../styles/field-label';
import { Col, Row } from 'react-bootstrap';
import TextArea from '../../components/custom-input-textarea';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import { trackParams } from '../../network/manager-analytics';

interface ContactData {
  name: string;
  email: string;
  phone: string;
  type: string;
  message: string;
}

const Contact: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [subject, setSubject] = useState('technical-informations');

  const handleSubmit = async (data: ContactData) => {
    try {
      data.type = subject;
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório').trim(),
        email: Yup.string().required('É-mail é obrigatório').trim(),
        phone: Yup.string().required('Telefone é obrigatório').trim(),
        message: Yup.string().required('Mensagem é obrigatório').trim(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setIsLoadingButton(true);

      await api.post('/contacts', { data });

      setIsLoadingButton(false);
      toast.success('Mensagem enviada com sucesso!', {
        position: 'top-right',
        theme: 'colored',
      });
      formRef.current?.setData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (err: any) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        setIsLoadingButton(false);
        err.response.data
          ? toast.error(`${translate(err.response.data)}. Tente Novamente!`, {
              position: 'top-right',
              theme: 'colored',
            })
          : toast.error(
              `${translate(err.response.data.error.message)}. Tente Novamente!`,
              {
                position: 'top-right',
                theme: 'colored',
              }
            );
      }
    }
  };
  return (
    <div className="shop-form" id="contato">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <h4
            className="recoverPassword text-center"
            style={{ color: '#4b90cf' }}
          >
            Contato
          </h4>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="text"
              name="name"
              placeholder="Nome"
              label="Nome"
              colorLabel="white"
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="email"
              name="email"
              placeholder="email@dominio.com.br"
              label="Email"
              colorLabel="white"
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="tel"
              name="phone"
              placeholder="Telefone"
              label="Telefone"
              colorLabel="white"
              inputMode="numeric"
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <FieldLabel htmlFor="subject">
              Assunto
              <sup>
                <span style={{ color: '#c00' }}> *</span>
              </sup>
            </FieldLabel>
            <select
              name="type"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            >
              <option value="technical-informations">
                Informações Técnicas
              </option>
              <option value="suggestions-complaints">
                Sugestão ou Reclamações
              </option>
              <option value="commercial-informations">
                Informações Comerciais
              </option>
              <option value="others">Outros</option>
            </select>
          </Col>
          <Col xl={10} className="m-auto p-0">
            <TextArea name="message" label="Mensagem" placeholder="Mensagem" />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <div className="d-flex justify-content-center btn-modal mb-0">
              <button
                type="submit"
                className="theme-btn btn-style-one col-12"
                disabled={isLoadingButton}
                onClick={() => {
                  trackParams('on_click_register_contact', location.pathname);
                }}
              >
                <span className="txt">
                  {isLoadingButton ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    'Enviar'
                  )}
                </span>
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default Contact;
