import React, { useCallback, useState } from 'react';
import Input from '../../components/custom-input';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { errorYup } from '../../util/bibli';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loading from '../../components/custom-loading';
import { Col, Row } from 'react-bootstrap';
import { trackParams } from '../../network/manager-analytics';

interface RecoverPasswordData {
  new_password: string;
  confirmation_password: string;
}

interface CloseModal {
  callback?: (value: string) => string;
}
const RecoverPassword: React.FC<CloseModal> = (props: CloseModal) => {
  const { callback } = props;

  const formRef = React.useRef(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handleSubmit = useCallback(async (data: RecoverPasswordData) => {
    try {
      const schema = Yup.object().shape({
        new_password: Yup.string()
          .required(
            'A senha deve ter no mínimo 8 caracteres com pelo menos 1 letra maiúscula, 1 minúscula e um caractér especial.'
          )

          .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_.])[A-Za-z\d@$!%*#?&-_.]{8,}$/,
            'A senha deve ter no mínimo 8 caracteres com pelo menos 1 letra maiúscula, 1 minúscula e um caractér especial.'
          )
          .trim(),
        confirmation_password: Yup.string()
          .when('new_password', {
            is: (val: any) => !!val.length,
            then: Yup.string().required('Confirmação de senha é obrigatória'),
            otherwise: Yup.string(),
          })
          .oneOf(
            [Yup.ref('new_password')],
            'As senhas não coincidem. Tente novamente.'
          )
          .trim(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setIsLoadingButton(true);

      await api.post('/auth/reset-password', {
        code: searchParams.get('code'),
        password: data.new_password,
        passwordConfirmation: data.confirmation_password,
      });

      setIsLoadingButton(false);

      callback && callback('closed');
      toast.success('Senha alterada com sucesso!', {
        position: 'top-right',
        theme: 'colored',
        style: {
          marginTop: `${
            location.pathname === '/sign-up/login' ||
            location.pathname === '/reset-password'
              ? '0'
              : '200px'
          }`,
        },
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        setIsLoadingButton(false);
        toast.error(
          `${
            err.response.data.error.message
              ? err.response.data.error.message
              : 'Erro. Entre em contato com nosso suporte!'
          }`,
          {
            position: 'top-right',
            theme: 'colored',
            style: {
              marginTop: `${
                location.pathname === '/sign-up/login' ||
                location.pathname === '/reset-password'
                  ? '0'
                  : '200px'
              }`,
            },
          }
        );
      }
    }
  }, []);

  return (
    <div className="shop-form form-recover-password" id="mudarSenha">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <h4
            className="recoverPassword text-center"
            style={{ color: '#4b90cf' }}
          >
            Redefinir Senha
          </h4>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="password"
              name="new_password"
              placeholder="Senha"
              label="Nova Senha"
              colorLabel="#ffffff"
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="password"
              name="confirmation_password"
              placeholder="Confirmar senha"
              label="Confirmação de senha"
              colorLabel="#ffffff"
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <div className="d-flex justify-content-center btn-modal">
              <button
                type="submit"
                className="theme-btn btn-style-one col-12"
                disabled={isLoadingButton}
                onClick={() => {
                  trackParams('on_click_recover_password', location.pathname);
                }}
              >
                <span className="txt">
                  {isLoadingButton ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    'Salvar'
                  )}
                </span>
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default RecoverPassword;
