import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../../components/custom-navigation/index';
import CardPlans from '../../components/custom-card-plans';
import { PlansContext } from '../../contexts/plans';
import { AuthContext } from '../../contexts/auth';

const Plans: React.FC = () => {
  const { plans, firstCheck, dataProduct, responseProduct, setDataProduct } =
    useContext(PlansContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    plans();
  }, []);
  return (
    <>
      <section className="pricing-section" id="detalhes-plano">
        <div className="container">
          <div className="sec-title sec-title-plans light centered">
            <h2 className="mb-4">Escolha seu pacote de créditos</h2>
            {responseProduct?.length > 1 && (
              <Navigation
                callback={(data: any) => {
                  setDataProduct([data]);
                }}
                responseProduct={responseProduct}
                type="plano"
                qtdeOptions={responseProduct?.length}
                firstSelected={firstCheck}
              />
            )}
          </div>
          <div className="row clearfix">
            <CardPlans dataCard={dataProduct} />
          </div>
        </div>
      </section>
    </>
  );
};
export default Plans;
