/* eslint-disable import/named */
import moment from 'moment';
import * as Yup from 'yup';
import getErrors from './getErrors';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';

export function errorYup(
  err: Yup.ValidationError,
  formRef: React.RefObject<FormHandles>
): void {
  if (err instanceof Yup.ValidationError) {
    const error = getErrors(err);
    const inputErr = Object.keys(error)[0];
    const input = formRef.current?.getFieldRef(inputErr);

    formRef.current?.setErrors(error);

    if (input.current) {
      input.current.focus();
    } else if (input) {
      input.focus();
    }
  }
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function validationDate(date: string, pFormat: string): boolean {
  const dateFormat = moment(date, pFormat, true);
  return dateFormat.isValid();
}

export function strToDate(date: string, format: string): Date {
  return moment(date, format).toDate();
}

export const cnpjMask = (value?: string) => {
  return (
    value &&
    value
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  ); // captura os dois últimos 2 números, com um - antes dos dois números
};

export function validarCNPJ(cnpj?: any): any {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function formatDate(date: any, mask: string) {
  const toFormat = new Date(date);
  return format(toFormat, mask);
}

export function formatDateFinal(date: any, qnt_month: number) {
  const expirationDate = date;
  expirationDate.setMonth(expirationDate.getMonth() + qnt_month);

  const expirationDateFormated = formatDate(expirationDate, 'yyyy-MM-dd');

  return expirationDateFormated;
}

export function translate(message: any) {
  switch (message) {
    case 'Invalid identifier or password':
      return 'Usuário ou senha inválida';
      break;
    case 'Please provide a valid email address':
      return 'Forneça um endereço de e-mail válido';
      break;
    case 'This email does not exist':
      return 'Este e-mail não existe';
      break;
    case 'Exceeded number of coupon uses':
      return 'Excedeu o número de usos do cupom';
      break;
    case 'coupon not found':
      return 'Cupom não encontrado';
      break;
    case 'Coupon not available for this product':
      return 'Cupom não disponível para este produto';
      break;
    case 'expired coupon':
      return 'Cupom expirado';
      break;
    case 'Method Not Allowed':
      return 'Método não permitido';
      break;
    case 'Coupon already used. Failed to create coupon.':
      return 'Falha ao criar cupom. Cupom já usado, tente outro cupom.';
      break;
    default:
      return message;
      break;
  }
}
