import React from 'react';

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import jsonLoading from '../../assets/json/loading.json';
import loadingButtonJson from '../../assets/json/loadingButton.json';

interface LoadingProps {
  loading_card?: boolean;
  loading_button?: boolean;
  visibled?: boolean;
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { loading_card, loading_button, visibled } = props;

  return (
    <div
      className={`${
        loading_button
          ? 'preloaderbutton'
          : loading_card
          ? 'preloadercard'
          : 'preloader'
      } ${
        loading_button || loading_card ? '' : visibled ? 'd-flex' : 'd-none'
      } `}
    >
      <span>
        <Player
          className="animationPlan"
          autoplay
          loop
          speed={1}
          src={loading_button ? loadingButtonJson : jsonLoading}
          style={{
            width: loading_button ? '' : '150px',
            height: loading_button ? '' : '150px',
          }}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>
      </span>
    </div>
  );
};

export default Loading;
