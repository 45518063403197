import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Container, Col, Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Input from '../../components/custom-input';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';

import { ContainerPage } from '../../styles/container-page';
import Navigation from '../../components/custom-navigation/index';
import {
  scrollToTop,
  errorYup,
  formatDate,
  formatDateFinal,
  translate,
} from '../../util/bibli';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Loading from '../../components/custom-loading';
import { Navigate, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import { trackParams } from '../../network/manager-analytics';
interface CouponData {
  name_link: string;
  name_coupon: string;
  validity_coupon: string;
}
const Partner: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);

  const [dataPartner, setDataPartner] = useState<any>([]);
  const [dataPartnerFinanc, setDataPartnerFinanc] = useState<any>([]);
  const { token } = useContext(AuthContext);
  const [infoPartnerInstructions, setInfoPartnerInstructions] =
    useState<any>(null);
  const [infoPartneResumes, setInfoPartneResumes] = useState<any>(null);

  const [codeLink, setCodeLink] = useState<any>(null);
  const [copied, setCopied] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [nameCoupom, setNameCoupom] = useState({ voucher: '' });

  function handleCouponInput(value: string) {
    setNameCoupom((prev: any) => ({ ...prev, voucher: value }));
  }

  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
    trackParams('screen_view', 'partner_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);
  }, []);

  useEffect(() => {
    getInstructions();
    getResumes();
  }, []);

  async function getInstructions() {
    try {
      setLoading(true);
      const resp_instructions = await api.get(`/coupon/instructions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resp_resumes_financial = await api.get(
        `/coupon/financial-resumes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let dados = [];
      dados = resp_instructions.data;
      dados.push({
        title: 'Financeiro',
        section: 'Financeiro',
        dados: resp_resumes_financial.data,
      });
      setDataPartner([resp_instructions.data[0]]);
      setInfoPartnerInstructions(dados);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      console.error('ops! ocorreu um erro' + err);
      if (err) {
        setLoading(false);
        navigate('/');

        err.response.data &&
          toast.error(`${err.response.data}. Tente Novamente!`, {
            position: 'top-center',
            theme: 'colored',
            style: {
              marginTop: screen.height * 0.4,
            },
          });
        err.response.data.error.message &&
          toast.error(`${err.response.data.error.message}. Tente Novamente!`, {
            position: 'top-center',
            theme: 'colored',
            style: {
              marginTop: screen.height * 0.4,
            },
          });
      }
    }
  }
  async function getResumes() {
    try {
      const response = await api.get(`/coupon/resumes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dados: any = [];
      response.data[1].table_data.forEach((data: any) => {
        dados.push(data.code);
      });
      setCodeLink(dados);
      setInfoPartneResumes(response.data);
    } catch (err: any) {
      console.log(err);
      console.error('ops! ocorreu um erro' + err);
      if (err) {
        navigate('/');
      }
      toast.error(`${err.response.data.error.message}. Tente Novamente!`, {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
      });
    }
  }

  const handleSubmit = useCallback(async (data: CouponData) => {
    try {
      let schema;
      if (data.validity_coupon || data.validity_coupon === '') {
        schema = Yup.object().shape({
          name_coupon: Yup.string()
            .required('Nome do cupom é obrigatório')
            .min(5, 'O nome do cupom precisa ter pelo menos 5 caracteres')
            .max(10, 'O nome do cupom não pode exceder 10 caracteres')
            .trim(),
          validity_coupon: Yup.string()
            .required('Validade do cupom é obrigatório')
            .trim(),
        });
      } else {
        schema = Yup.object().shape({
          name_link: Yup.string()
            .required('Nome do link é obrigatório')
            .min(5, 'O nome do link precisa ter pelo menos 5 caracteres')
            .max(15, 'O nome do link não pode exceder 10 caracteres')
            .trim(),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });
      setIsLoadingButton(true);
      await api.post(
        '/coupon/create',
        {
          alias:
            (data.name_coupon && data.name_coupon.replace(/\s/g, '')) ||
            (data.name_link && data.name_link),
          code:
            (data.name_coupon && data.name_coupon.replace(/\s/g, '')) ||
            (data.name_link && data.name_link),
          validate: data.name_link ? '' : data.validity_coupon,
          type: data.name_link ? 'link' : 'coupon',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await getResumes();
      setIsLoadingButton(false);
      formRef.current?.setData({
        name_link: '',
        name_coupon: '',
        validity_coupon: data.validity_coupon,
      });
      toast.success(`${data.name_link ? 'Link' : 'Cupom'} criado sucesso!`, {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
        autoClose: 1000,
      });
    } catch (err: any) {
      console.log(err);
      formRef.current?.setData({
        name_link: '',
        name_coupon: '',
        validity_coupon: data.validity_coupon,
      });
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        setIsLoadingButton(false);
        err.response.data
          ? toast.error(translate(err.response.data), {
              position: 'top-center',
              theme: 'colored',
              style: {
                marginTop: screen.height * 0.4,
              },
              autoClose: 2000,
            })
          : toast.error('Ocorreu um erro!', {
              position: 'top-center',
              theme: 'colored',
              style: {
                marginTop: screen.height * 0.4,
              },
              autoClose: 1000,
            });
      }
    }
  }, []);

  return (
    <>
      <Loading visibled={loading} />
      <Header />
      <Row className="m-0 header-page">
        <Col lg={12} className="page-title page-title-partner">
          <div className="auto-container">
            <h2>Parceiro</h2>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Parceiro</li>
            </ul>
          </div>
        </Col>
      </Row>
      <ContainerPage>
        <div className="auto-container" id="partner_page">
          <div className="pt-4 pb-4" style={{ backgroundColor: '#F2F2F2' }}>
            <Navigation
              callback={(data: any) => {
                if (data.title === 'Financeiro') {
                  setDataPartner([]);
                  setDataPartnerFinanc([data]);
                } else {
                  setDataPartnerFinanc([]);
                  setDataPartner([data]);
                }
              }}
              responseProduct={infoPartnerInstructions}
              qtdeOptions={infoPartnerInstructions?.length}
              firstSelected={0}
              type="parceiro"
              noMessage
            />
            {dataPartnerFinanc && dataPartnerFinanc.length > 0 ? (
              <Row>
                <Col lg={12} md={12} sm={12} className="pt-4">
                  <div
                    className="shop-order-box"
                    style={{ backgroundColor: '#F2F2F2' }}
                  >
                    <div className="no-order-list">
                      <h3 className="text-center">Resumo Financeiro</h3>
                      <div className="shop-form">
                        <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                        <Table borderless size="sm" responsive="lg">
                          <thead>
                            <tr
                              className="text-center"
                              style={{ fontSize: '18px' }}
                            >
                              {dataPartnerFinanc[0] &&
                                dataPartnerFinanc[0].dados &&
                                dataPartnerFinanc[0].dados.table_header &&
                                Object.values(
                                  dataPartnerFinanc[0].dados.table_header
                                ).map((data: any, idx: number) => {
                                  return <th key={idx}>{data.title}</th>;
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {dataPartnerFinanc[0] &&
                              dataPartnerFinanc[0].dados &&
                              dataPartnerFinanc[0].dados.table_data.map(
                                (data: any, idx: number) => {
                                  return (
                                    <tr className="text-center" key={idx}>
                                      {Object.values(data).map(
                                        (info: any, idx: number) => {
                                          return <td key={idx}>{info}</td>;
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={12} md={12} sm={12} className="pt-4" id="parceiros">
                  <div
                    className="shop-order-box"
                    style={{ backgroundColor: '#F2F2F2' }}
                  >
                    {dataPartner?.map((dados: any, idx: number) => {
                      return (
                        <div id="Cupons" key={`id_${idx}`}>
                          <div className="no-order-list">
                            <h3 className="text-center">
                              {dados.how_it_works.title}
                            </h3>
                            {dados.how_it_works.messages.map(
                              (info: any, idx: number) => {
                                return <p key={`id_${idx}`}>{info}</p>;
                              }
                            )}

                            <div>
                              <h3 className="text-center">
                                {dados.build_coupon.title}
                              </h3>
                              <div className="shop-form">
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                  <Row>
                                    {dados.build_coupon.fields.map(
                                      (info: any, idx: number) => {
                                        return info.type === 'input' &&
                                          info.title === 'Nome do link' ? (
                                          <Col
                                            sm={12}
                                            md={8}
                                            xl={9}
                                            key={`id_${idx}`}
                                          >
                                            <Input
                                              type="text"
                                              name="name_link"
                                              placeholder={info.title}
                                              label="Nome"
                                              maxLength={15}
                                              onChange={(e) => {
                                                handleCouponInput(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </Col>
                                        ) : info.type === 'input' &&
                                          info.title === 'Nome do cupom' ? (
                                          <Col
                                            sm={12}
                                            md={5}
                                            xl={6}
                                            key={`id_${idx}`}
                                          >
                                            <Input
                                              type="text"
                                              name="name_coupon"
                                              label="Nome"
                                              placeholder={info.title}
                                              maxLength={10}
                                              onKeyDown={(e: any) => {
                                                if (e.key === ' ') {
                                                  e.preventDefault();
                                                }
                                              }}
                                              onChange={(e) => {
                                                handleCouponInput(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </Col>
                                        ) : info.type === 'input' &&
                                          info.title === 'Validade' ? (
                                          <Col
                                            sm={14}
                                            md={3}
                                            xl={3}
                                            key={`id_${idx}`}
                                          >
                                            <Input
                                              type="date"
                                              name="validity_coupon"
                                              placeholder="Validade"
                                              label="Validade"
                                              min={`${formatDate(
                                                new Date(),
                                                'yyyy-MM-dd'
                                              )}`}
                                              max={`${formatDateFinal(
                                                new Date(),
                                                2
                                              )}`}
                                              defaultValue={`${formatDateFinal(
                                                new Date(),
                                                2
                                              )}`}
                                            />
                                          </Col>
                                        ) : (
                                          <Col
                                            sm={12}
                                            md={4}
                                            xl={3}
                                            className="button-create-coupon"
                                            style={{ marginTop: '28px' }}
                                            key={`id_${idx}`}
                                          >
                                            <button
                                              type="submit"
                                              className="theme-btn btn-style-one col-12"
                                              onClick={() => {
                                                trackParams(
                                                  'on_click_create_coupon',
                                                  location.pathname,
                                                  [
                                                    {
                                                      propertie:
                                                        dados.build_coupon
                                                          .title,
                                                      value: nameCoupom.voucher,
                                                    },
                                                  ]
                                                );
                                              }}
                                            >
                                              <span className="txt">
                                                {isLoadingButton ? (
                                                  <div
                                                    style={{
                                                      height: 30,
                                                    }}
                                                  >
                                                    <Loading loading_button />
                                                  </div>
                                                ) : (
                                                  info.title
                                                )}
                                              </span>
                                            </button>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Form>
                              </div>
                              {dados.messages.map((info: any, idx: number) => {
                                return (
                                  <div
                                    className="m-auto pt-3"
                                    style={{ width: '95%' }}
                                    key={`id_${idx}`}
                                  >
                                    <ul>
                                      <li style={{ listStyle: 'none' }}>
                                        {info}
                                      </li>
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>

                            <Row>
                              <Col lg={12} md={12} sm={12} className="pt-4">
                                <h3 className="text-center pt-4">
                                  {infoPartneResumes &&
                                    infoPartneResumes[
                                      dataPartner[0]?.section === 'coupom'
                                        ? 0
                                        : 1
                                    ]?.section}
                                </h3>
                                <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                                <Table borderless size="sm" responsive="lg">
                                  <thead>
                                    <tr
                                      className="text-center"
                                      style={{ fontSize: '18px' }}
                                    >
                                      {infoPartneResumes &&
                                        infoPartneResumes[
                                          dataPartner[0]?.section === 'coupom'
                                            ? 0
                                            : 1
                                        ]?.table_header &&
                                        Object.values(
                                          infoPartneResumes &&
                                            infoPartneResumes[
                                              dataPartner[0]?.section ===
                                              'coupom'
                                                ? 0
                                                : 1
                                            ]?.table_header
                                        ).map((data: any, idx: number) => {
                                          return (
                                            <th key={idx}>{data.title}</th>
                                          );
                                        })}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {infoPartneResumes &&
                                      infoPartneResumes[
                                        dataPartner[0]?.section === 'coupom'
                                          ? 0
                                          : 1
                                      ]?.table_data &&
                                      infoPartneResumes &&
                                      infoPartneResumes[
                                        dataPartner[0]?.section === 'coupom'
                                          ? 0
                                          : 1
                                      ]?.table_data.map(
                                        (data: any, index: number) => {
                                          data.name && delete data.code;
                                          return (
                                            <tr
                                              className="text-center"
                                              key={`id_${index}`}
                                            >
                                              {Object.values(data).map(
                                                (info: any, idx: number) => {
                                                  return (
                                                    <td key={`id_${idx}`}>
                                                      {info}
                                                    </td>
                                                  );
                                                }
                                              )}
                                              {dataPartner[0]?.section ===
                                              'coupom' ? (
                                                ''
                                              ) : (
                                                <td>
                                                  <CopyToClipboard
                                                    key={`id_${idx}`}
                                                    text={`${location.origin}/?link=${codeLink[index]}`}
                                                    onCopy={() => {
                                                      setCopied(true);
                                                      toast.success(
                                                        'Link copiado com sucesso!',
                                                        {
                                                          position:
                                                            'top-center',
                                                          theme: 'colored',
                                                          style: {
                                                            marginTop:
                                                              screen.height *
                                                              0.4,
                                                          },
                                                        }
                                                      );
                                                      trackParams(
                                                        'on_copy_link',
                                                        location.pathname,
                                                        [
                                                          {
                                                            propertie: 'Link',
                                                            value: `${location.origin}/?link=${codeLink[index]}`,
                                                          },
                                                        ]
                                                      );
                                                    }}
                                                  >
                                                    <button className="theme-btn btn-style-one-copy col-6">
                                                      <span
                                                        className="fa fa-copy"
                                                        style={{
                                                          fontSize: 25,
                                                        }}
                                                      />
                                                    </button>
                                                  </CopyToClipboard>
                                                </td>
                                              )}
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </ContainerPage>
      <Footer />
    </>
  );
};
export default Partner;
