export const getCookie = (cName: string) => {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const setCookie = (name: string, val: string) => {
  const NODE_ENV = process.env.REACT_APP_NODE_ENV;

  const domain = NODE_ENV === 'development' ? 'localhost' : '.faaftech.com';

  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 3600 * 1000 * 24 * 365 * 10;
  now.setTime(expireTime);

  console.log(`${name}=${val}; Path=/; Expires=${now}, domain=${domain}`);

  document.cookie = `${name}=${val}; Path=/; Expires=${now}, domain=${domain}`;
};
