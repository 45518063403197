import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import global from '../../global';

import '../../App.css';
import '../../styles/css/header.css';
import '../../styles/css/style.css';
import '../../styles/css/footer.css';
import '../../styles/css/bootstrap.css';
import '../../styles/css/global.css';
import '../../styles/css/owl.css';
import '../../styles/css/responsive.css';
import '../../styles/css/creditCard.css';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import Selo from '../../components/custom-selo';
import Plans from '../Plans';
import About from './About';
import Carousel from '../../components/custom-carousel';
import ModalForm from '../../components/custom-modal-form';
import Loading from '../../components/custom-loading';
import { PlansContext } from '../../contexts/plans';
import { trackParams } from '../../network/manager-analytics';

function Dashboard() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = useContext(PlansContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const sendIdentifierConfirmation = () => {
    const identifierConfirmation = searchParams.get('identifierConfirmation');
    const emailConfirmation = searchParams.get('emailConfirmation');

    if (!identifierConfirmation) {
      return;
    }

    const dataTrack = [
      {
        propertie: 'identifier_refer_confirmation',
        value: identifierConfirmation,
      },
      {
        propertie: 'email_refer_confirmation',
        value: emailConfirmation,
      },
      {
        propertie: 'identifier_user',
        value: global.identifierUser,
      },
    ];

    trackParams('relate_identifier_unknown', 'home_screen', dataTrack);
  };

  useEffect(() => {
    trackParams('screen_view', 'home_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);

    sendIdentifierConfirmation();

    if (
      location.pathname === '/reset-password' ||
      location.pathname === '/sign-up/login' ||
      location.pathname === '/sign-up/complete-company'
    ) {
      setIsActive(true);
    }
  }, [location.pathname]);

  return (
    <>
      <Loading visibled={loading} />
      <Header />
      <Carousel />
      <About />
      <Plans />
      <Selo />
      <Footer />
      <ModalForm
        isActive={isActive}
        callback={(value: string) => {
          if (value == 'closed') {
            navigate('/');
            setIsActive(false);
          }
          return value;
        }}
      />
    </>
  );
}

export default Dashboard;
