/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import Input from '../../components/custom-input';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FooterLogin } from '../../styles/footer-login';
import { errorYup } from '../../util/bibli';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import { PlansContext } from '../../contexts/plans';
import { Col, Row } from 'react-bootstrap';
import Loading from '../../components/custom-loading';
import { trackParams } from '../../network/manager-analytics';

interface SignProps {
  onClick: any;
  title?: string;
  colorLabel?: string;
  colorLabelFooter: string;
  callback?: (value: string) => string;
}
interface SignInData {
  email: string;
  password: string;
}

const SignIn: React.FC<SignProps> = (props: SignProps) => {
  const formRef = React.useRef(null);
  const { onClick, title, colorLabel, colorLabelFooter, callback } = props;
  const { signIn } = useContext(AuthContext);
  const { plans } = useContext(PlansContext);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  function handleChange(e: any) {
    // Here, we invoke the callback with the new value
    if (e.target.classList.contains('forgotPassword')) {
      onClick('forgot-password');
    } else if (e.target.classList.contains('createAccount')) {
      onClick('create');
    }
  }
  const handleSubmit = async (data: SignInData) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Email é obrigatório')
          .email('Digite um email válido')
          .trim(),
        password: Yup.string().required('Senha é obrigatória').trim(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setIsLoadingButton(true);
      if (location.pathname === '/sign-up/login') {
        await signIn(data.email, data.password, '', '', plans, callback);
      } else if (location.pathname === '/sign-up/partner') {
        await signIn(data.email, data.password, '', location.pathname);
      } else {
        await signIn(data.email, data.password, id, '');
      }
      setIsLoadingButton(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        toast.error(err.response.data.error.message, {
          position: `${
            location.pathname === '/sign-up/login' ? 'top-right' : 'top-center'
          }`,
          theme: 'colored',
          style: {
            marginTop: `${
              location.pathname === '/sign-up/login' ? '0' : '200px'
            }`,
          },
        });
      }
    }
  };
  return (
    <div className="shop-form form-signIn" id="login">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <h4 className="signUp text-center" style={{ color: '#4b90cf' }}>
            {title}
          </h4>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="text"
              name="email"
              placeholder="email@dominio.com.br"
              label="Email"
              colorLabel={colorLabel}
              mandatory
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="password"
              name="password"
              placeholder="***********"
              label="Senha"
              colorLabel={colorLabel}
              mandatory
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <div className="d-flex justify-content-center btn-modal mb-0">
              <button
                type="submit"
                className="theme-btn btn-style-one col-12"
                disabled={isLoadingButton}
                onClick={() => {
                  trackParams('on_click_login', location.pathname);
                }}
              >
                <span className="txt">
                  {isLoadingButton ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    'Entrar'
                  )}
                </span>
              </button>
            </div>
          </Col>

          <FooterLogin className="col-xl-10 m-auto p-0">
            <a
              className="theme-btn btn-recover-password"
              onClick={handleChange}
            >
              <span
                className="txt forgotPassword"
                style={{ fontWeight: 400, color: `${colorLabelFooter}` }}
              >
                Recuperar senha
              </span>
            </a>
            <a className="theme-btn btn-criar-conta" onClick={handleChange}>
              <span
                color={colorLabelFooter}
                className="txt createAccount"
                style={{ fontWeight: 400, color: `${colorLabelFooter}` }}
              >
                Cadastrar
              </span>
            </a>
          </FooterLogin>
        </Row>
      </Form>
    </div>
  );
};

export default SignIn;
