import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import { Field, FormLabel } from './styled';
import { Error } from '../../styles/error';
import { FieldLabel } from '../../styles/field-label';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: JSX.Element | string;
  maxLength?: number;
  placeholder?: string;
  enabled?: boolean;
}
const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  maxLength,
  placeholder,
  enabled = true,
  ...rest
}: TextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  function handleOnChange(ev: any) {
    clearError();
    const { value } = ev.currentTarget;
  }
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <Field isError={!!error} disabled={!enabled}>
        <textarea
          id="textArea"
          className="form-control"
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          disabled={!enabled}
          defaultValue={rest.value}
          onChange={handleOnChange}
          maxLength={maxLength}
          style={{ borderColor: `${error ? 'red' : ''}` }}
        />
      </Field>
      {error && (
        <Error className="invalid-feedback d-block m-0 bold-600">{error}</Error>
      )}
    </>
  );
};

export default TextArea;
