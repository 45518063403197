import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import Rotas from './route';
import AppContext from './contexts/index';
import { ToastContainer } from 'react-toastify';
import './global';
const App: React.FC = () => {
  return (
    <Router>
      <AppContext>
        <ToastContainer />
        <Rotas />
      </AppContext>
    </Router>
  );
};

export default App;
