/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import { PlansContext } from '../../contexts/plans';
import { trackParams } from '.././../network/manager-analytics';
import { Toggle, SubHeaderContainer } from './styled';
import {
  RadioContainer,
  ToggleSlider,
  Label,
  Separator,
} from '../../styles/radio-container';
import { useLocation } from 'react-router-dom';

interface NavProps {
  type: 'plano' | 'meses' | 'pagamento' | 'conta' | 'parceiro' | 'default';
  qtdeOptions: number;
  firstSelected?: number;
  responseProduct?: any;
  callback?: Function;
  noMessage?: boolean;
  disableAll?: boolean;
}

const Navigation: React.FC<NavProps> = (props: NavProps) => {
  const {
    type,
    responseProduct,
    qtdeOptions,
    firstSelected,
    callback,
    noMessage,
    disableAll,
  } = props;

  const address = localStorage.getItem('@Customeasy:AddressUser');
  const address_user = address && JSON.parse(address);

  const [toggle, setToggle] = useState('');
  const [border, setBorder] = useState('');
  const [check, setCheck] = useState(true);
  const [checkChange, setCheckChange] = useState<string>();

  const { user } = useContext(AuthContext);
  const { change } = useContext(PlansContext);

  const left = 100 / qtdeOptions;
  const location = useLocation();

  useEffect(() => {
    if (firstSelected != null) {
      setCheck(true);
      setToggle(`${left * firstSelected}%`);
    }
    if (firstSelected === 0 && type === 'plano') {
      setBorder('0px 2px');
    } else if (firstSelected === 1 && type === 'plano') {
      setBorder('0px -2px');
    }
    if (firstSelected === 0) {
      setBorder('0px 2px');
    }
  }, [change, left, firstSelected, type]);
  return (
    <div className="types">
      <Toggle className="toggleRadio">
        {responseProduct?.map((item: any, index: number) => {
          return (
            <RadioContainer key={`id_${index}`}>
              <input
                type="radio"
                value={index}
                className="radioInput"
                name={type}
                id={item.title}
                checked={
                  check
                    ? index === firstSelected
                    : index.toString() === checkChange
                }
                disabled={
                  disableAll
                    ? true
                    : user && type === 'pagamento' && !address_user
                    ? index === 0 || index === 2
                      ? true
                      : false
                    : user && type === 'pagamento' && address_user
                    ? index === 0
                      ? true
                      : false
                    : !user && type === 'pagamento'
                    ? index === 0
                      ? false
                      : true
                    : false
                }
                onChange={(e) => {
                  trackParams('on_change_navigation', location.pathname, [
                    {
                      propertie: type,
                      value: item.section || item.title,
                    },
                  ]);
                  setCheck(false);
                  setCheckChange(e.target.value);
                  callback && callback(item, index);
                  index === 0 ? setToggle('0%') : setToggle(`${left * index}%`);
                  index === 0 ? setBorder('0px 2px') : setBorder('');
                }}
              />
              <Label htmlFor={item.title} className="toggleRadioLabel">
                {item.icon ? (
                  <div>
                    {item.icon} {item.title}
                  </div>
                ) : (
                  item.title
                )}
              </Label>
            </RadioContainer>
          );
        })}
        <ToggleSlider
          left={toggle}
          margin={border}
          width={`${left}%`}
          className="toggleSlider"
          id="toggleSlider"
        />
      </Toggle>
      <SubHeaderContainer className={`${noMessage ? 'd-none' : 'd-flex'}`}>
        {responseProduct?.map((items: any, index: number) => {
          return (
            <div
              key={`id_${index}`}
              className="subHeader"
              style={{ width: '33.3333%' }}
            >
              {items.message}
            </div>
          );
        })}
      </SubHeaderContainer>
    </div>
  );
};

export default Navigation;
