import React, { useContext, useState } from 'react';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import Input from '../../components/custom-input';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import { errorYup } from '../../util/bibli';
import api from '../../services/api';
import { AuthContext } from '../../contexts/auth';
import { ProductContext } from '../../contexts/product';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FieldLabel } from '../../styles/field-label';
import { Error } from '../../styles/error';
import Loading from '../../components/custom-loading';
import axios from 'axios';
import { trackParams } from '../../network/manager-analytics';

interface SignInData {
  street: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
const Address: React.FC = () => {
  const { authenticated, getMe } = useContext(AuthContext);
  const formRef = React.useRef<FormHandles>(null);
  const address = localStorage.getItem('@Customeasy:AddressUser');
  const address_user = address && JSON.parse(address);
  const navigate = useNavigate();
  const location = useLocation();
  const id = address_user?.id;

  const [errSelect, setErrSelect] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [uf, setUf] = useState(`${address_user?.state || ''}`);

  const states = [
    { value: '', label: '' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const handleSubmit = async (data: SignInData) => {
    try {
      if (uf === '') {
        setErrSelect(true);
      }
      data.country = 'BR';
      data.state = uf;
      const schema = Yup.object().shape({
        street: Yup.string().required('Endereço é obrigatório').trim(),
        city: Yup.string().required('Cidade é obrigatória').trim(),
        zipcode: Yup.string()
          .required('CEP é obrigatório')
          .test(
            'len',
            'CEP deve ter exatamente 8 caracteres',
            (val: any) => val.length === 8
          )
          .trim(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setIsLoadingButton(true);
      if (uf) {
        if (id) {
          await api.put(
            `/addresses/${id} `,
            { data },
            {
              headers: {
                Authorization: `Bearer ${authenticated?.authUserToken}`,
              },
            }
          );
        } else {
          await api.post(
            '/addresses',
            { data },
            {
              headers: {
                Authorization: `Bearer ${authenticated?.authUserToken}`,
              },
            }
          );
        }
        // await getInfoaddresses(authenticated, '', location.pathname);
        await getMe(authenticated?.authUserToken);
        navigate(`${location.pathname}`, { replace: true });
        toast.success('Endereço alterado com sucesso!', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: `${
              location.pathname === '/sign-up/login' ||
              location.pathname === '/reset-password'
                ? '0'
                : '200px'
            }`,
          },
        });
      } else {
        toast.error('É necessário informar o estado!', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }

      setIsLoadingButton(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        setIsLoadingButton(false);
        console.log(err);
        toast.error(`${err.response.data.error.message}. Tente Novamente!`, {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }
    }
  };

  const handleCepInput = async (value: string) => {
    if (value.length > 7) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      const cep = response.data.cep.replace(/[^\d]+/g, '');
      formRef.current?.setData({
        zipcode: cep || value,
        street: response.data.logradouro || '',
        city: response.data.localidade || '',
        complement: response.data.complemento || '',
        number: '',
      });
      setUf(response.data.uf);
      setErrSelect(false);
      if (response.data.logradouro)
        formRef.current?.setFieldError('street', '');
      if (response.data.localidade) formRef.current?.setFieldError('city', '');
    }
  };
  return (
    <>
      <div className="shop-form " id="address">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Col xl={10} className="p-0 m-auto">
              <Input
                type="text"
                name="zipcode"
                placeholder="00000-000"
                label="CEP"
                mandatory
                defaultValue={`${address_user?.zipcode || ''}`}
                onChange={(val) => {
                  handleCepInput(val.target.value);
                }}
                maxLength={8}
              />
            </Col>
            <Col xl={10} className="p-0 m-auto">
              <Input
                type="text"
                name="street"
                placeholder="Rua, Avenida, Via..."
                label="Endereço"
                mandatory
                defaultValue={`${address_user?.street || ''}`}
              />
            </Col>
            <Row className="justify-content-center m-0 p-0">
              <Col xl={3} className="p-0 numero">
                <Input
                  type="text"
                  name="number"
                  placeholder="Nº"
                  label="Número"
                  defaultValue={`${address_user?.number || ''}`}
                />
              </Col>
              <Col xl={7} className="ps-3 pe-0 complemento">
                <Input
                  type="text"
                  name="complement"
                  placeholder="Quadra, Lote, Casa, Apto..."
                  label="Complemento"
                  defaultValue={`${address_user?.complement || ''}`}
                />
              </Col>
            </Row>

            <Row className="justify-content-center m-0 p-0">
              <Col xl={7} className=" p-0">
                <Input
                  type="text"
                  name="city"
                  placeholder="Cidade"
                  label="Cidade"
                  mandatory
                  defaultValue={`${address_user?.city || ''}`}
                />
              </Col>
              <Col xl={3} className="ps-3 pe-0 estado">
                <FieldLabel htmlFor="state" color="#404040">
                  Estado
                  <sup>
                    <span style={{ color: '#c00' }}> *</span>
                  </sup>
                </FieldLabel>
                <select
                  name="state"
                  id="state"
                  value={uf}
                  onChange={(e) => {
                    setUf(e.target.value);
                    setErrSelect(false);
                  }}
                >
                  {states.map((info: any, index: number) => {
                    return (
                      <option value={info.value} key={`id_${index}`}>
                        {info.value}
                      </option>
                    );
                  })}
                </select>
                {errSelect ? (
                  <Error className="invalid-feedback d-block m-0 bold-600">
                    Estado é obrigatório
                  </Error>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <div className="pt-4 pb-4 ps-0 pe-0 col-xl-10 m-auto">
              <button
                type="submit"
                className="theme-btn btn-style-one col-12"
                disabled={isLoadingButton}
                onClick={() => {
                  trackParams('on_click_register_address', location.pathname);
                }}
              >
                <span className="txt">
                  {isLoadingButton ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    'Salvar'
                  )}
                </span>
              </button>
            </div>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Address;
