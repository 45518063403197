import styled from 'styled-components';

interface InputProps {
  isError: boolean;
  disabled: boolean;
}
export const FormLabel = styled.label`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #777;
  left: 0;
  z-index: 2;
`;

export const Field = styled.div<InputProps>`
  background-color: ${(props) => (props.disabled ? '#e9ecef' : '#ffffff')};
`;
