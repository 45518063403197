import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import Loading from '../../components/custom-loading';

const SignOut: React.FC = () => {
  const { signOut, authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function handleSignOut() {
      try {
        signOut();
      } catch (err: any) {
        console.log(err);
      }
    }
    if (authenticated.user && authenticated.authUserToken) {
      handleSignOut();
    } else {
      navigate('/', { replace: true });
    }
  }, [signOut, authenticated]);

  return (
    <>
      <Header />
      <Loading visibled={true} />
      <Footer />
    </>
  );
};

export default SignOut;
