import styled from 'styled-components';

export const Toggle = styled.div`
  max-width: 504px;
  position: relative;
  background: hsl(0deg 0% 100% / 95%);
  margin: 0 auto;
  height: 42px;
  overflow: hidden;
  padding: 0;
  border-radius: 999px;
  display: flex;
  width: 100%;
  box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 50%);
  padding: 2px 0px;
`;
export const SubHeaderContainer = styled.div`
  max-width: 504px;
  position: relative;
  margin: 0 auto;
  height: 32px;
  display: flex;
  width: 100%;
`;
