/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useState } from 'react';
import logo from '../../assets/images/Faaftech-logo.png';
import { Link, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../util/bibli';
import { AuthContext } from '../../contexts/auth';
import { HashLink } from 'react-router-hash-link';
import ModalCe from '../custom-modal';
import Terms from '../../pages/Terms';
import { trackParams } from '../../network/manager-analytics';

const Footer: React.FC = () => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [modal, setModal] = useState({ active: false });

  return (
    <>
      <ModalCe
        size="lg"
        title="Termos de uso CustomEasy"
        subTitle={<Terms />}
        titleButtonLeft="Concordo"
        controllerModal={[modal.active, setModal]}
      />
      <footer className="main-footer" id="contato">
        <div className="auto-container">
          <div className="row">
            <div className="col-sm-3">
              <div className="logo-footer text-center">
                <img src={logo} alt="Logo Faaftech" loading="lazy" />
                <div className="social">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/faaftech"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      trackParams(
                        'on_click_social_networks',
                        location.pathname,
                        [
                          {
                            propertie: 'Rede Social',
                            value: 'Facebook',
                          },
                        ]
                      );
                    }}
                  />
                  <a
                    className="youtube"
                    href="https://www.youtube.com/user/Faaftech"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      trackParams(
                        'on_click_social_networks',
                        location.pathname,
                        [
                          {
                            propertie: 'Rede Social',
                            value: 'Youtube',
                          },
                        ]
                      );
                    }}
                  />
                  <a
                    className="instagram"
                    href="https://www.instagram.com/faaftech/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      trackParams(
                        'on_click_social_networks',
                        location.pathname,
                        [
                          {
                            propertie: 'Rede Social',
                            value: 'Instagram',
                          },
                        ]
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-9 d-none d-sm-block text-left">
              <ul className="menu-footer">
                <li
                  id="menu-item-52"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-52 active"
                >
                  <Link
                    title="Home"
                    to="/"
                    onClick={() => {
                      scrollToTop();
                      trackParams('on_click_footer_menu', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Home',
                        },
                      ]);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li
                  id="menu-item-114"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-114"
                >
                  <HashLink
                    to="/#planos"
                    onClick={() => {
                      scrollToTop();
                      trackParams('on_click_footer_menu', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Pacotes',
                        },
                      ]);
                    }}
                  >
                    Pacotes
                  </HashLink>
                </li>
                {user && (
                  <li
                    id="menu-item-116"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-116"
                  >
                    <Link
                      title="Conta"
                      to="/account"
                      onClick={() => {
                        scrollToTop();
                        trackParams('on_click_footer_menu', location.pathname, [
                          {
                            propertie: 'Menu',
                            value: 'Conta',
                          },
                        ]);
                      }}
                    >
                      Conta
                    </Link>
                  </li>
                )}
                <li
                  id="menu-item-117"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-117"
                >
                  <Link
                    title="Contato"
                    to="/contact"
                    state={{ backgroundLocation: location }}
                    onClick={() => {
                      scrollToTop();
                      trackParams('on_click_footer_menu', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Contato',
                        },
                      ]);
                    }}
                  >
                    Contato
                  </Link>
                </li>
                <li
                  id="menu-item-32712"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32712"
                >
                  <a
                    href=""
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal({ active: true });
                      trackParams('on_click_footer_menu', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Termos de uso',
                        },
                      ]);
                    }}
                  >
                    Termos de uso
                  </a>
                </li>
                <li
                  id="menu-item-32713"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32713"
                >
                  <a
                    title="Política de Privacidade"
                    href="https://www.faaftech.com/politica-de-privacidade/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal({ active: true });
                      trackParams('on_click_footer_menu', location.pathname, [
                        {
                          propertie: 'Menu',
                          value: 'Política de Privacidade',
                        },
                      ]);
                    }}
                  >
                    Política de Privacidade
                  </a>
                </li>
              </ul>
              <div className="row">
                <div className="col-sm-6 d-none d-sm-block">
                  <h3>Endereço</h3>
                  <br />
                  <p>
                    Rua 90, 929 – Setor Sul
                    <br />
                    Goiânia GO
                    <br />
                    CEP: 74093-020
                  </p>
                </div>
                <div className="col-sm-6 d-none d-sm-block">
                  <h3>Contato</h3>
                  <br />
                  <p>
                    Comercial: comercial@faaftech.com
                    <br />
                    +55 (62) 3241-4600
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="copyright">
                  Copyright © 2021 FAAFTECH - Soluções automotivas inteligentes
                  - Todos os direitos reservados.
                  <a href="https://www.faaftech.com/politica-de-privacidade/">
                    Política de Privacidade
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
