/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/named */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Card, { Focused } from 'react-credit-cards';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import Checkbox from '../../../components/custom-checkbox';
import Input from '../../../components/custom-input';
import { Box } from '../../../styles/box';
import { CardCE } from '../../../styles/card';
import { ProductContext } from '../../../contexts/product';
import Loading from '../../../components/custom-loading';
import { trackParams } from '../../../network/manager-analytics';

import { toast } from 'react-toastify';

import Countdown from 'react-countdown';

interface PaymentProps {
  buttonTitle?: string;
  isMobile?: boolean;
  modalFunction?: () => null;
  callback?: (param: boolean) => void;
}

let now = Date.now();

const PixForm: React.FC<PaymentProps> = (props: PaymentProps) => {
  const { isMobile, modalFunction, buttonTitle, callback } = props;

  const [showQRCode, setShowQRCode] = useState(false);
  const formRefs = useRef(null);
  const {
    handlePayment,
    setFormRef,
    handleTermsOfUse,
    setCheckboxEquals,
    isLoadingButtonProd,
    paymentResponse,
    setFormSelected,
  } = useContext(ProductContext);

  async function handleSubmit() {
    now = Date.now();

    handleTermsOfUse();
    handlePayment();
  }

  useEffect(() => {
    setFormSelected('bank-transfer');
    setFormRef(formRefs);
  }, []);

  useEffect(() => {
    if (paymentResponse?.recurrenceData?.Payment?.QrCodeBase64Image) {
      setShowQRCode(true);
      return;
    }

    if (!paymentResponse?.recurrenceData?.Payment?.QrCodeBase64Image) {
      setShowQRCode(false);
      return;
    }
  }, [paymentResponse]);

  const renderer = ({
    minutes,
    seconds,
    completed,
  }: {
    [key: string]: any;
  }) => {
    if (completed) {
      // Render a completed state
      return <p>O tempo expirou</p>;
    } else {
      // Render a countdown

      if (seconds < 10) {
        return (
          <div
            style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold' }}
          >{`${minutes}:0${seconds}`}</div>
        );
      }

      return (
        <div
          style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold' }}
        >{`${minutes}:${seconds}`}</div>
      );
    }
  };

  return (
    <div className="shop-form">
      <Form ref={formRefs} onSubmit={handleSubmit}>
        <Row>
          {showQRCode ? (
            <Row className="justify-content-center m-0 p-0">
              <p>Aqui seu QR Code para pagamento via PIX</p>
              <Countdown date={now + 899000} renderer={renderer} />
              ,
              <img
                alt="pix"
                src={`data:image/jpeg;base64,${paymentResponse?.recurrenceData?.Payment?.QrCodeBase64Image}`}
              />
              <div style={{ marginTop: 10 }}>
                <p>
                  Se preferir, você pode utilizar o Pix copia e cola clicando no
                  botão abaixo.
                </p>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(
                      paymentResponse?.recurrenceData?.Payment?.QrCodeString
                    );
                    toast.success(
                      'Código copiado para a área de transferencia',
                      {
                        position: 'top-center',
                        theme: 'colored',
                        style: {
                          marginTop: screen.height * 0.4,
                        },
                        autoClose: 1500,
                      }
                    );
                  }}
                  className="theme-btn btn-style-one col-12"
                >
                  <span className="txt">Pix copia e cola</span>
                </div>
              </div>
            </Row>
          ) : (
            <>
              <Col xl={10} className="p-0 m-auto">
                <p>
                  Clique em comprar para gerar um QR Code PIX válido por 15
                  minutos.
                </p>
              </Col>
              <Row className="justify-content-center m-0 p-0">
                <Col xl={10} className="pt-4 pb-3 ps-0 pe-0">
                  <button
                    disabled={isLoadingButtonProd}
                    type="submit"
                    className="theme-btn btn-style-one col-12"
                  >
                    <span className="txt">
                      {isLoadingButtonProd ? (
                        <div
                          style={{
                            height: 30,
                          }}
                        >
                          <Loading loading_button />
                        </div>
                      ) : (
                        buttonTitle || 'Comprar Agora'
                      )}
                    </span>
                  </button>
                </Col>
                {isMobile && (
                  <Col xl={10} className="m-auto pe-0 ps-0">
                    <Checkbox
                      noMargin
                      name="termos_uso"
                      onChange={(e: any) => setCheckboxEquals(e)}
                      label={
                        <>
                          Concordo com os{' '}
                          <a
                            href=""
                            rel="noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              modalFunction && modalFunction();
                            }}
                          >
                            Termos de uso
                          </a>
                        </>
                      }
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default PixForm;
