import React, { useCallback, useState } from 'react';
import Input from '../../components/custom-input';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { errorYup, translate } from '../../util/bibli';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/custom-loading';
import { Col, Row } from 'react-bootstrap';
import { trackParams } from '../../network/manager-analytics';
interface ForgotProps {
  colorLabel?: string;
}
interface ForgotData {
  email: string;
}
const ForgotPassword: React.FC<ForgotProps> = (props: ForgotProps) => {
  const { colorLabel } = props;
  const formRef = React.useRef(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const location = useLocation();
  const handleSubmit = useCallback(async (data: ForgotData) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('É-mail é obrigatório').trim(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setIsLoadingButton(true);
      await api.post('auth/forgot-password', {
        email: data.email,
      });
      setIsLoadingButton(false);
      toast.success('Email enviado com sucesso!', {
        position: `${
          location.pathname === '/sign-up/login' ? 'top-right' : 'top-center'
        }`,
        theme: 'colored',
        style: {
          marginTop: `${
            location.pathname === '/sign-up/login' ? '0' : '200px'
          }`,
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        return;
      } else {
        setIsLoadingButton(false);
        toast.error(
          `${translate(err.response.data.error.message)}. Tente Novamente!`,
          {
            position: `${
              location.pathname === '/sign-up/login'
                ? 'top-right'
                : 'top-center'
            }`,
            theme: 'colored',
            style: {
              marginTop: `${
                location.pathname === '/sign-up/login' ? '0' : '200px'
              }`,
            },
          }
        );
      }
    }
  }, []);
  return (
    <div className="shop-form form-recover-password" id="mudarSenha">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <h4
            className="recoverPassword text-center"
            style={{ color: '#4b90cf' }}
          >
            Recuperar Senha
          </h4>
          <Col xl={10} className="m-auto p-0">
            <Input
              type="text"
              name="email"
              placeholder="email@dominio.com.br"
              label="Email"
              colorLabel={colorLabel}
            />
          </Col>
          <Col xl={10} className="m-auto p-0">
            <div className="d-flex justify-content-center btn-modal">
              <button
                type="submit"
                className="theme-btn btn-style-one col-12"
                disabled={isLoadingButton}
                onClick={() => {
                  trackParams('on_click_forgot_password', location.pathname);
                }}
              >
                <span className="txt">
                  {isLoadingButton ? (
                    <div
                      style={{
                        height: 30,
                      }}
                    >
                      <Loading loading_button />
                    </div>
                  ) : (
                    'Enviar'
                  )}
                </span>
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default ForgotPassword;
