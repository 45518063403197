import global from '../global';
import apiTrack from '../services/apiTrack';
interface DataTrack {
  propertie: string;
  value: string;
}

export const trackParams = async (
  event_name: string,
  page: string,
  params?: any
) => {
  try {
    await trackData(event_name, page, params);
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

async function trackData(event_name: string, page: string, data?: [DataTrack]) {
  const body = {
    event_name: event_name,
    source: 'web',
    page,
    identifier_user: global.identifierUser,
    data: data ? data : [],
    platform: window.navigator.platform,
  };

  try {
    const response = await apiTrack.post('/tracks/create', body);
    return response;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
}

export const sendNotificationCallback = async (identifierUser: string) => {
  const body = {
    identifier_user: identifierUser,
  };

  try {
    const response = await apiTrack.post(
      '/user-tracks/notification-callback',
      body
    );
    return response;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};
