/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../services/api';
import * as Yup from 'yup';
import { errorYup, translate } from '../util/bibli';
import { v4 as uuidv4 } from 'uuid';
import { setCookie, getCookie } from './../util/cookieUtils';

interface User {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  jwt: string;
}

interface Address {
  id: number;
  street: string;
  number: string;
  complement: string;
  zipcode: boolean;
  city: boolean;
  state: string;
  country: string;
}

interface AuthUserState {
  user: User;
  authUserToken: string;
  address?: Address | null;
}

interface AuthUser {
  user: User;
  authUserToken: string;
  address?: Address | null;
  route?: string;
  path?: string;
}
interface AuthContextData {
  children?: ReactNode;
}
interface AuthContextD {
  signIn: Function;
  signUp: Function;
  signOut: Function;
  // getInfoaddresses: Function;
  getMe: Function;
  authenticated: AuthUser;
  user: any;
  token: any;
  setToken: any;
  loadingAuth: boolean;
  setLoadingAuth: Dispatch<SetStateAction<boolean>>;
  formRef: any;
  setFormRef: Function;
  companyButton: boolean;
  setCompanyButton: Dispatch<SetStateAction<boolean>>;
  dataMe: any;
  setDataMe: any;
  errSignIn: any;
  setErrSignIn: Dispatch<SetStateAction<any>>;
}
export const AuthContext = createContext<AuthContextD>({} as AuthContextD);

const AuthProvider: React.FC<AuthContextData> = (props: AuthContextData) => {
  const { children } = props;

  const navigate = useNavigate();

  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const [dataMe, setDataMe] = useState<any>();
  const [formRef, setFormRef] = useState<any>({});
  const [companyButton, setCompanyButton] = useState<boolean>(false);
  const [errSignIn, setErrSignIn] = useState<any>();
  const [authenticated, setAuthenticated] = useState<AuthUserState>(() => {
    const authUserToken = localStorage.getItem('@Customeasy:AuthUserToken');
    const user = localStorage.getItem('@Customeasy:UserID');
    const address = localStorage.getItem('@Customeasy:AddressUser');

    if (authUserToken && user && address) {
      return {
        authUserToken,
        user: JSON.parse(user),
        address: address ? JSON.parse(address) : address,
      };
    }
    return {} as AuthUserState;
  });
  const [token, setToken] = React.useState<any>(authenticated.authUserToken);

  //setando o LINK
  const getLinkPartner = localStorage.getItem('@Customeasy:LinkPartner');

  const queryStr = new URLSearchParams(window.location.search);
  const valueStr = queryStr.get('link');

  if (location.search && valueStr && getLinkPartner !== valueStr) {
    localStorage.setItem('@Customeasy:LinkPartner', valueStr);
  }

  async function signIn(
    login: string,
    password: string,
    url_param: string,
    path: string,
    plans: (value: string) => string,
    callback: (value: string) => string
  ) {
    try {
      setLoadingAuth(true);
      const response = await api.post('auth/local', {
        identifier: login,
        password: password,
      });
      setToken(response.data.jwt);
      if (url_param) {
        await getMe(response.data.jwt || response.data.authUserToken);
      }
      await setLogin({
        user: response.data.user,
        authUserToken: response.data.jwt || response.data.authUserToken,
        route: url_param,
        path: path,
      });
      callback && callback('closed');
      plans && (await plans(response.data.jwt || response.data.authUserToken));
      setLoadingAuth(false);
    } catch (err: any) {
      console.log(err);
      setLoadingAuth(false);
      setErrSignIn(err.response.data.error.message);
      toast.error(translate(err.response.data.error.message), {
        position: `${
          location.pathname === '/sign-up/login' ? 'top-right' : 'top-center'
        }`,
        theme: 'colored',
        style: {
          marginTop: `${
            location.pathname === '/sign-up/login' ? '0' : '200px'
          }`,
        },
      });
    }
  }

  async function getMe(authToken: string) {
    try {
      const response = await api.get(`/users/me`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      localStorage.setItem(
        '@Customeasy:Company',
        JSON.stringify(response.data.company)
      );

      localStorage.setItem(
        '@Customeasy:AddressUser',
        JSON.stringify(
          response?.data?.addresses[0] ? response?.data?.addresses[0] : null
        )
      );
      setDataMe(response.data);
      return response;
    } catch (err: any) {
      console.log(err);
    }
  }

  const signUp = async () => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório').trim(),
        email: Yup.string().required('É-mail é obrigatório').trim(),
        password: Yup.string()
          .required(
            'A senha deve ter no mínimo 8 caracteres com pelo menos 1 letra maiúscula, 1 minúscula e um caractér especial.'
          )

          .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_.])[A-Za-z\d@$!%*#?&-_.]{8,}$/,
            'A senha deve ter no mínimo 8 caracteres com pelo menos 1 letra maiúscula, 1 minúscula e um caractér especial.'
          )
          .trim(),
        politica_privacidade: Yup.string()
          .matches(/S/g, 'Campo Obrigatório')
          .trim(),
      });
      await schema.validate(formRef.current?.getData(), {
        abortEarly: false,
      });
      setLoadingAuth(true);
      const resp = await api.post('auth/local/register', {
        full_name: formRef.current?.getData().name,
        username: formRef.current?.getData().email,
        email: formRef.current?.getData().email,
        code_refer: formRef.current?.getData().code_refer,
        password: formRef.current?.getData().password,
      });
      toast.success(
        <div>
          <p
            style={{ color: '#ffffff', margin: 0, fontWeight: 500 }}
          >{`${resp.data.popup.titulo_popup}`}</p>
          <span
            style={{ color: '#ffffff', fontWeight: 'normal' }}
            dangerouslySetInnerHTML={{
              __html: `${resp.data.popup.descricao_popup}`,
            }}
          />
        </div>,
        {
          autoClose: 8000,
          position: `${
            location.pathname === '/sign-up/login' ? 'top-right' : 'top-center'
          }`,
          theme: 'colored',
          style: {
            marginTop: `${
              location.pathname === '/sign-up/login' ? '20px' : '100px'
            }`,
          },
        }
      );
      formRef.current?.setData({
        name: '',
        email: '',
        code_refer: '',
        password: '',
      });
      setLoadingAuth(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setLoadingAuth(false);
        errorYup(err, formRef);
        if (formRef.current?.getData().politica_privacidade === 'N') {
          toast.error(
            'É necessário concordar com os termos de uso e política de privacidade!',
            {
              autoClose: 10000,
              position: `${
                location.pathname === '/sign-up/login'
                  ? 'top-right'
                  : 'top-center'
              }`,
              theme: 'colored',
              style: {
                marginTop: `${
                  location.pathname === '/sign-up/login' ? '20px' : '100px'
                }`,
              },
            }
          );
        }
        return;
      } else {
        console.log(err);
        setLoadingAuth(false);
        toast.error(
          `${
            err.response.data.message ? err.response.data.message : 'Erro'
          }. Entre em contato com nosso suporte!`,
          {
            autoClose: 10000,
            position: `${
              location.pathname === '/sign-up/login'
                ? 'top-right'
                : 'top-center'
            }`,
            theme: 'colored',
            style: {
              marginTop: `${
                location.pathname === '/sign-up/login' ? '20px' : '100px'
              }`,
            },
          }
        );
      }
    }
  };

  const setLogin = useCallback(
    async (auth: AuthUser) => {
      const { authUserToken, user, address, route, path } = auth;
      localStorage.setItem('@Customeasy:AuthUserToken', authUserToken);
      localStorage.setItem('@Customeasy:UserID', JSON.stringify(user));
      const company = localStorage.getItem('@Customeasy:Company');

      setAuthenticated({
        user,
        authUserToken,
      });
      if (route) {
        navigate(`/checkout/${route}/details`, { replace: true });
      } else if (path) {
        navigate(`${path}`);
      } else if (companyButton && company === 'null') {
        navigate('/sign-up/complete-company');
      } else if (companyButton && company !== 'null') {
        setCompanyButton(false);
        window.location.href = '#detalhes-plano';
      } else {
        navigate('/');
      }
    },
    [companyButton, navigate]
  );

  function signOut() {
    const userIdentifierValue = uuidv4();

    // localStorage.setItem('@Customeasy:Identifier', userIdentifierValue);
    setCookie('identifier_user', userIdentifierValue);

    localStorage.removeItem('@Customeasy:AuthUserToken');
    localStorage.removeItem('@Customeasy:UserID');
    localStorage.removeItem('@Customeasy:AddressUser');
    localStorage.removeItem('@Customeasy:Company');

    setAuthenticated({ authUserToken: '' } as AuthUserState);
    setToken('');
    setDataMe('');
    setCompanyButton(false);
    setLoadingAuth(true);
    navigate('/');
  }
  return (
    <AuthContext.Provider
      value={{
        signIn,
        signUp,
        signOut,
        // getInfoaddresses,
        getMe,
        user: authenticated.user,
        authenticated: authenticated,
        token,
        setToken,
        loadingAuth,
        setLoadingAuth,
        formRef,
        setFormRef,
        companyButton,
        setCompanyButton,
        dataMe,
        setDataMe,
        errSignIn,
        setErrSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
