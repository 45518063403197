import React from 'react';
import { Modal } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { trackParams } from '../../network/manager-analytics';

interface ModalProps {
  title?: string;
  subTitle?: any;
  path?: string;
  pathLeft?: string;
  pathSection?: string;
  titleButtonLeft?: string;
  titleButtonRigth?: string;
  controllerModal: [boolean, React.Dispatch<React.SetStateAction<any>>];
  size?: 'sm' | 'lg' | 'xl';
  onClick?: (param: any) => any;
}

const ModalCe: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    title,
    subTitle,
    path,
    pathLeft,
    titleButtonLeft,
    titleButtonRigth,
    controllerModal,
    size,
    onClick,
  } = props;
  const [showModal, setShowModal] = controllerModal;
  const handleClose = () => {
    setShowModal(false);
    trackParams('on_click_modal_option', location.pathname, [
      {
        propertie: `${title}`,
        value: titleButtonLeft,
      },
    ]);
  };

  return (
    <Modal
      scrollable
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{subTitle}</Modal.Body>
      <Modal.Footer>
        {titleButtonLeft ? (
          pathLeft ? (
            <HashLink
              to={pathLeft}
              state={{ previousRoute: location.pathname }}
              className="theme-btn btn-style-six col-12 col-xl-6"
              onClick={() => {
                trackParams('on_click_modal_option', location.pathname, [
                  {
                    propertie: `${title}`,
                    value: titleButtonLeft,
                  },
                ]);
              }}
            >
              <span className="txt">{titleButtonLeft}</span>
            </HashLink>
          ) : (
            <button
              className="theme-btn btn-style-six col-12 col-xl-6"
              onClick={handleClose}
            >
              <span className="txt">{titleButtonLeft}</span>
            </button>
          )
        ) : (
          <></>
        )}
        {onClick ? (
          <button
            className="theme-btn btn-style-seven col-12 col-xl-5"
            onClick={onClick}
          >
            <span className="txt">{titleButtonRigth}</span>
          </button>
        ) : titleButtonRigth ? (
          <HashLink
            to={path ? path : '/'}
            className="theme-btn btn-style-seven col-12 col-xl-5"
            onClick={() => {
              trackParams('on_click_modal_option', location.pathname, [
                {
                  propertie: `${title}`,
                  value: titleButtonRigth,
                },
              ]);
            }}
          >
            <span className="txt">{titleButtonRigth}</span>
          </HashLink>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalCe.defaultProps = {
  size: undefined,
};
export default ModalCe;
