/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/CustomEady---logo11.png';
import SignIn from '../../pages/SignIn';
import ForgotPassword from '../../pages/ForgotPassword';
import SignUp from '../../pages/SignUp';
import Contact from '../../pages/Contact';
import FormPartner from '../../pages/FormPartner';
import { AuthContext } from '../../contexts/auth';
import { PlansContext } from '../../contexts/plans';

import RecoverPassword from '../../pages/RecoverPassword';
import { trackParams } from '../../network/manager-analytics';
interface ModalPros {
  isActive: boolean;
  callback?: (value: string) => string;
}
const ModalForm: React.FC<ModalPros> = (props: ModalPros) => {
  const { isActive, callback } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [formActive, setFormActive] = React.useState('');
  const { companyButton, setCompanyButton, setLoadingAuth } =
    useContext(AuthContext);

  const closeModalForm = () => {
    setLoadingAuth(true);
    if (location.pathname === '/contact') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };
  function handleChange(newFormActive: React.SetStateAction<string>) {
    setFormActive(newFormActive);
  }

  function showForm() {
    trackParams('screen_view', 'modal_form_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);
    if (location.pathname === '/sign-up/login') {
      return formActive === '' ? (
        <SignIn
          onClick={handleChange}
          title="Login"
          colorLabel="#e0e0e0"
          colorLabelFooter="#e0e0e0"
          callback={callback}
        />
      ) : formActive === 'forgot-password' ? (
        <ForgotPassword colorLabel="#e0e0e0" />
      ) : (
        <SignUp colorLabel="#e0e0e0" />
      );
    }
    if (location.pathname === '/sign-up/complete-company') {
      return <FormPartner callback={callback} colorLabel="#e0e0e0" />;
    }
    if (location.pathname === '/contact') {
      return <Contact />;
    }

    if (location.pathname === '/reset-password') {
      return <RecoverPassword callback={callback} />;
    }
  }

  return (
    <>
      <div
        className={`xs-sidebar-group info-group  ${isActive ? 'isActive' : ''}`}
        id="modal-form"
      >
        <div className="xs-overlay xs-bg-black" />
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div
              className={`d-flex ${
                formActive === ''
                  ? 'justify-content-end'
                  : 'justify-content-between'
              }`}
            >
              <div
                className={`widget-heading return-widget position-relative float-left ${
                  formActive === '' ? 'd-none' : 'd-block'
                }`}
              >
                <a
                  onClick={() => {
                    setLoadingAuth(true);
                    setFormActive('');
                  }}
                  className="return-side-widget"
                  style={{ color: '#4b90cf', fontSize: '1.6rem' }}
                >
                  <span className="fa fa-arrow-left" id="arrow-back" />
                </a>
              </div>
              <div className="widget-heading position-relative" id="widget-x">
                <a
                  href=""
                  className="close-side-widget"
                  onClick={(e) => {
                    e.preventDefault();
                    setCompanyButton(false);
                    if (callback) {
                      setLoadingAuth(true);
                      callback('closed');
                      return;
                    }
                    closeModalForm();
                  }}
                >
                  X
                </a>
              </div>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner d-flex justify-content-center">
                  <div className="logo">
                    <a href="/" className="d-flex justify-content-center">
                      <img src={logo} alt="Logo customeasy" width="70%" />
                    </a>
                  </div>
                </div>
                {showForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalForm;
