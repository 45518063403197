import React, { useContext } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import SignUpPartner from '../pages/SignUpPartner';
import Checkout from '../pages/Checkout';
import Account from '../pages/Account';
import Partner from '../pages/Partner';
import ModalForm from '../components/custom-modal-form';
import SignOut from '../pages/SignOut';
import { AuthContext } from '../contexts/auth';
import ServiceTerms from '../pages/ServiceTerms';
interface PrivateProps {
  children: any;
}
const Rotas: React.FC = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const { user, authenticated } = useContext(AuthContext);

  //My Private Route
  const PrivateRoute: React.FC<PrivateProps> = (props: PrivateProps) => {
    const { children } = props;
    const auth = authenticated.authUserToken;
    return auth ? children : <Navigate to="/" />;
  };

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/home" element={<Dashboard />} />
        <Route path="/terms-of-services" element={<ServiceTerms />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/checkout/:id/details" element={<Checkout />} />
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path="/partner"
          element={
            <PrivateRoute>
              <Partner />
            </PrivateRoute>
          }
        />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/reset-password" element={<Dashboard />} />
        <Route path="/sign-up/partner" element={<SignUpPartner />} />
        <Route path="/sign-up/:id" element={<Dashboard />} />
        {/* Componente para quando não encontrar uma rota */}
        <Route path="*" element={<Dashboard />} />
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route path="/contact" element={<ModalForm isActive />} />
        </Routes>
      )}
    </>
  );
};
export default Rotas;
