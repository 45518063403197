import { v4 as uuidv4 } from 'uuid';
import { sendNotificationCallback } from './network/manager-analytics';
import { setCookie, getCookie } from './util/cookieUtils';

// const identifierUser = localStorage.getItem('@Customeasy:Identifier');
let identifierUser = getCookie('identifier_user') || '';

const getUserIdentifier = async (identifier: string) => {
  const userIdentifierValue = uuidv4();

  if (!identifier) {
    try {
      setCookie('identifier_user', userIdentifierValue);
      // localStorage.setItem('@Customeasy:Identifier', userIdentifierValue);
      identifierUser = userIdentifierValue;
      await sendNotificationCallback(userIdentifierValue);
    } catch (err) {
      console.log('failed to store user');
    }
  }
};

getUserIdentifier(identifierUser);

export default {
  identifierUser,
};
