import styled from 'styled-components';
interface checkboxCheck {
  labelColor?: string;
}
interface LabelCheckboxProps {
  checked: boolean;
  isError: boolean;
  disabled: boolean;
  withPadding?: boolean;
}
interface marginFieldProps {
  noMargin?: boolean;
}
export const Field = styled.div<marginFieldProps>`
  position: relative;
  margin: ${(props) => (props.noMargin ? '0' : '0 0 20px')};
`;
export const FormCheckbox = styled.input`
  position: absolute;
  margin-left: 0;
  height: 30px;
  width: 30px;
  left: -5px;
  z-index: 5;
  opacity: 0;
`;

export const LabelCheckbox = styled.label<LabelCheckboxProps>`
  display: flex;
  align-items: center;
`;

export const Text = styled.p<checkboxCheck>`
  margin-bottom: 0;
  color: ${(props) => (props.labelColor ? props.labelColor : '#404040')};
  font-size: 1rem;
  margin-left: 5px;
`;

export const Box = styled.label`
  min-width: 25px;
`;
