import React from 'react';
import logo from '../../assets/images/customEasy logo para fundo escuro 3.png';
import slide from '../../assets/images/main-slider/slide.jpg';
import slideMobile from '../../assets/images/main-slider/slideMobile.jpg';

const Carousel: React.FC = () => {
  return (
    <section className="main-slider" id="carousel">
      <div className="main-slider-carousel owl-carousel owl-theme owl-loaded owl-drag">
        <div className="slide p-0 d-flex justify-content-center">
          <img
            src={slide}
            srcSet={`${slideMobile} 300w, ${slideMobile} 600w, ${slide} 900w`}
            sizes="(max-width: 300px) 100vw, (max-width: 900px) 50vw"
            alt="Escolha um pacote do tamanho da sua demanda e leve o mundo de personalizações para mais carros"
            className="w-100 img-slider"
          />
          <div
            className="auto-container position-absolute"
            style={{ bottom: 0 }}
          >
            <div className="content-boxed">
              <div className="inner-box text-left" id="inner-box">
                <img src={logo} alt="Logo CustomEasy" />
                <h1 style={{ opacity: 1, transform: 'none' }}>
                  Escolha um pacote de créditos do tamanho da sua demanda e leve
                  o mundo de personalizações para mais carros
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
