import '../../App.css';
import '../../styles/css/header.css';
import '../../styles/css/style.css';
import '../../styles/css/footer.css';
import '../../styles/css/bootstrap.css';
import '../../styles/css/global.css';
import '../../styles/css/owl.css';
import '../../styles/css/responsive.css';
import '../../styles/css/creditCard.css';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import Terms from '../Terms';

function ServiceTerms() {
  return (
    <>
      <Header />

      <div
        style={{
          marginTop: '100px',
          marginLeft: '15px',
          marginRight: '15px',
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <h2>Termos de serviços</h2>
        </div>
        <Terms />
      </div>

      <Footer />
    </>
  );
}

export default ServiceTerms;
