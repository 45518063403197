import styled from 'styled-components';

export const InnerContainer = styled.div`
  background-color: #ffffff;
  padding-top: 40px;
`;

export const LogoCielo = styled.div`
  padding-bottom: 40px;
  padding-top: 1rem;
`;

export const Ten = styled.div`
  p {
    text-align: left;
  }
`;
