/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/named */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Card, { Focused } from 'react-credit-cards';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import Checkbox from '../../../components/custom-checkbox';
import Input from '../../../components/custom-input';
import { Box } from '../../../styles/box';
import { CardCE } from '../../../styles/card';
import { ProductContext } from '../../../contexts/product';
import Loading from '../../../components/custom-loading';
import { trackParams } from '../../../network/manager-analytics';

interface PaymentProps {
  buttonTitle?: string;
  isMobile?: boolean;
  modalFunction?: () => null;
  callback?: (param: boolean) => void;
}

const BankSlip: React.FC<PaymentProps> = (props: PaymentProps) => {
  const { isMobile, modalFunction, buttonTitle, callback } = props;

  const [showQRCode, setShowQRCode] = useState(false);
  const formRefs = useRef(null);
  const {
    handlePayment,
    setFormRef,
    handleTermsOfUse,
    setCheckboxEquals,
    isLoadingButtonProd,
    paymentResponse,
    setFormSelected,
  } = useContext(ProductContext);

  async function handleSubmit() {
    handleTermsOfUse();
    handlePayment();
  }

  useEffect(() => {
    setFormSelected('bank-slip');
    setFormRef(formRefs);
  }, []);

  // useEffect(() => {}, [isLoadingButtonProd]);

  useEffect(() => {
    if (paymentResponse?.recurrenceData?.Payment?.Url) {
      setShowQRCode(true);
      callback && callback(true);
    }
  }, [paymentResponse]);

  return (
    <div className="shop-form">
      <Form ref={formRefs} onSubmit={handleSubmit}>
        <Row>
          <>
            <Col xl={10} className="p-0 m-auto">
              <p>
                A compensação do boleto pode demorar até 3 dias úteis para ser
                confirmada
              </p>
              <p>
                Após cliar em Comprar, você receberá o link para acessar o
                boleto.
              </p>
            </Col>
            <Row className="justify-content-center m-0 p-0">
              <Col xl={10} className="pt-4 pb-3 ps-0 pe-0">
                {showQRCode ? (
                  <a
                    href={paymentResponse?.recurrenceData?.Payment?.Url}
                    target="_blank"
                    className="theme-btn btn-style-one col-12"
                    rel="noreferrer"
                  >
                    <span className="txt">Ver Boleto</span>
                  </a>
                ) : (
                  <button
                    disabled={isLoadingButtonProd}
                    type="submit"
                    className="theme-btn btn-style-one col-12"
                  >
                    <span className="txt">
                      {isLoadingButtonProd ? (
                        <div
                          style={{
                            height: 30,
                          }}
                        >
                          <Loading loading_button />
                        </div>
                      ) : (
                        buttonTitle || 'Comprar Agora'
                      )}
                    </span>
                  </button>
                )}
              </Col>
              {isMobile && (
                <Col xl={10} className="m-auto pe-0 ps-0">
                  <Checkbox
                    noMargin
                    name="termos_uso"
                    onChange={(e: any) => setCheckboxEquals(e)}
                    label={
                      <>
                        Concordo com os{' '}
                        <a
                          href=""
                          rel="noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            modalFunction && modalFunction();
                          }}
                        >
                          Termos de uso
                        </a>
                      </>
                    }
                  />
                </Col>
              )}
            </Row>
          </>
        </Row>
      </Form>
    </div>
  );
};

export default BankSlip;
