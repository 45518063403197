import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';

import { Field, FormCheckbox, LabelCheckbox, Box, Text } from './styled';
import noCheckError from '../../assets/images/icons/no_check_error.svg';
import noCheck from '../../assets/images/icons/no_check.svg';
import check from '../../assets/images/icons/check.svg';
import { trackParams } from '../../network/manager-analytics';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: any;
  labelColor?: string;
  onChange?: any;
  controller?: ['S' | 'N', React.Dispatch<React.SetStateAction<'S' | 'N'>>];
  initialValue?: 'S' | 'N';
  noMargin?: boolean;
  withPadding?: boolean;
}

const Checkbox: React.FC<InputProps> = ({
  name,
  label,
  labelColor,
  onChange,
  controller,
  initialValue,
  noMargin,
  withPadding,
  ...rest
}: InputProps) => {
  const { fieldName, error, registerField, clearError } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const controllerComponent = useState(initialValue);

  const [valueIpt, setValueIpt] = controller || controllerComponent;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => (ref.current.checked ? 'S' : 'N'),
      clearValue: () => {
        // eslint-disable-next-line no-param-reassign
        setValueIpt('N');
      },
      setValue: (ref, value: 'S' | 'N') => {
        // eslint-disable-next-line no-param-reassign
        setValueIpt(value);
      },
    });
  }, [fieldName, registerField, setValueIpt]);

  function handleOnChange(event: React.SyntheticEvent<HTMLInputElement>) {
    console.log(event);
    clearError();
    trackParams('on_change_checkbox', location.pathname, [
      {
        propertie: `Checkbox ${event.currentTarget.name}`,
        value: event.currentTarget.checked,
      },
    ]);
    setValueIpt(event.currentTarget.checked ? 'S' : 'N');
    if (onChange) onChange(event);
  }
  return (
    <Field noMargin={noMargin}>
      <LabelCheckbox
        disabled={rest.disabled || false}
        checked={valueIpt === 'S'}
        isError={!!error}
        withPadding={withPadding}
        id="checkbox-img"
      >
        <Box>
          <img
            src={valueIpt === 'S' ? check : error ? noCheckError : noCheck}
            alt="check"
          />
        </Box>

        <Text labelColor={labelColor}>{label}</Text>
        <FormCheckbox
          ref={inputRef}
          type="checkbox"
          name={name}
          checked={valueIpt === 'S'}
          disabled={rest.disabled}
          onChange={handleOnChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </LabelCheckbox>
    </Field>
  );
};

Checkbox.defaultProps = {
  onChange: undefined,
  controller: undefined,
  initialValue: 'N',
  noMargin: false,
  withPadding: false,
  labelColor: '#404040',
};

export default Checkbox;
